import { useSelector } from "react-redux";
import { Axios } from "../Hooks/useAxiosInstance";


export const TournamentAPI = () => {

    const { route, user } = useSelector(state => state.default)

    const apis = ({ route, api_token }) => ({
        get: async () => await getRecord(route, api_token),
        getLive: async () => await getLiveRecord(api_token),
        getTournamentLiveMatches: async (id) => await getTournamentLiveMatchesRecord(id, api_token),
        getByID: async (id) => await getRecordByID(id, route, api_token),
        getTeamsByID: async (id) => await getTeamsByAgeGroupID(id, route, api_token),
        getFixtureByID: async (id) => await getFixtureByAgeGroupID(id, route, api_token),
    })

    async function getRecord(route, api_token) {
        const url = route + "tournament/schedule";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getLiveRecord(api_token) {
        const url = "user/tournament/live";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getTournamentLiveMatchesRecord(id, api_token) {
        const url = "user/tournament/" + id + "/live-match";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getRecordByID(id, route, api_token) {
        const url = route + "tournament/" + id;
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getTeamsByAgeGroupID(id, route, api_token) {
        const url = route + "tournament/age-group/" + id + '/team';
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getFixtureByAgeGroupID(id, route, api_token) {
        const url = "user/tournament/age-group/" + id + '/fixture';
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    return apis({ route: route, api_token: user.api_token });
}

