import React, { useContext, useEffect, useState } from "react";
import DesktopLayout from "./DesktopLayout";
import { BREAKPOINTS } from "../../constants";
import MobileLayout from "./MobileLayout";
import ChatContextProvider from "./Context/ChatContext";


export default function Messages() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= BREAKPOINTS.MOBILE);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);

        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, [])


    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= BREAKPOINTS.MOBILE)
    }


    return (
        <ChatContextProvider>
            {isMobile ? <MobileLayout /> :
                <DesktopLayout />}
        </ChatContextProvider>
    )
}