import React, { useMemo, useState } from 'react'
import { Button, Input } from '../../../Components/CustomComponents'
import { Col, Image, Row } from 'react-bootstrap'
import cssStyles from "../index.module.css";
import TeamMember from './TeamMember';
import { useMutation, useQueryClient } from 'react-query';
import Upload from "rc-upload"
import _ from 'lodash';
import { Toast } from '../../../Hooks/useToast';
import { FaPlus } from "react-icons/fa6";
import { TeamAPI } from '../../../Api/Team';

const CreateTeamCard = ({ playerAge = undefined, selectedPlayer = [], handleClose = () => { } }) => {
    const AGE_QUERY_KEY = 'user/age-group'
    const PLAYER_QUERY_KEY = 'age-group/' + playerAge + '/team/unregister'
    const ACCEPT_IMAGE = 'image/png, image/jpeg, image/jpg'

    const teamAPI = TeamAPI();
    const queryClient = useQueryClient();

    const [name, setName] = useState('');
    const [image, setImage] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const age_group = useMemo(() => {
        if (!playerAge) return undefined;
        const age_data = queryClient.getQueryData([AGE_QUERY_KEY]);
        const group = (age_data?.data ?? [])?.find(item => item.value === playerAge) ?? {}
        return group?.label
    }, [playerAge])

    const team_members = useMemo(() => {
        if (!playerAge) return [];
        const player_data = queryClient.getQueryData([PLAYER_QUERY_KEY]);
        const players = (player_data?.data ?? [])?.filter(item => selectedPlayer.includes(item.slug)) ?? []
        return players
    }, [selectedPlayer])



    const registerMutation = useMutation(teamAPI.post, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['age-group/' + playerAge + '/team/unregister'],
                {
                    refetchInactive: true,
                    refetchActive: true,
                })
            queryClient.invalidateQueries(['team'],
                {
                    refetchInactive: true,
                    refetchActive: true,
                })
            queryClient.invalidateQueries(['team/unregister'],
                {
                    refetchInactive: true,
                    refetchActive: true,
                })
            handleClose()
            setIsSubmitted(false)
        },
        onError: (err) => {
            Toast(err.message, 'error', false)
            setIsSubmitted(false)
        }
    });

    const handleChangeName = (name, value) => {
        setName(value);
    };

    const handleImageChange = (e) => {
        if (!ACCEPT_IMAGE.includes(e.type)) return Toast('Invalid media type', 'error', false)

        setImage(e);
        return false;
    };

    const handleSubmit = () => {
        if (isSubmitted) return;
        if (!name) return Toast("Team name is required", 'error', false);
        if (_.isEmpty(image)) return Toast("Team image is required", 'error', false);
        if (!selectedPlayer.length) return Toast("Select player to create team", 'error', false);

        setIsSubmitted(true);
        const formData = new FormData();
        formData.append('name', name)
        formData.append('image_url', image)
        formData.append('group_type', playerAge)
        selectedPlayer.forEach((player, index) => formData.append(`team_members[${index}]`, player))

        registerMutation.mutate(formData);
    }

    return (
        <div style={{
            backgroundColor: "#0D0D1C",
            borderRadius: "22px 22px 0px 0px",
            width: "100%",
            height: "100%",
            padding: '20px',
        }}
        >
            <h4 className='text-white' >Create Your Team</h4>

            <p className=' text-white fs-7'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

            <Row>
                <Col md={4} sm={4} xs={4} className='d-flex flex-column align-items-center  '>
                    <Upload
                        beforeUpload={handleImageChange}
                        multiple={false}
                        accept={ACCEPT_IMAGE}
                    >
                        {_.isEmpty(image) ?
                            <div
                                style={{ width: "80px", height: "80px", borderRadius: "100%", border: "0.5px dashed lightgrey", display: "flex", alignItems: "center", justifyContent: "center" }}
                            >
                                <FaPlus style={{ fontSize: "1rem" }} />
                            </div> :
                            <Image
                                src={URL.createObjectURL(image)}
                                roundedCircle
                                style={{ width: "80px", height: "80px", borderRadius: "100%" }}
                            />
                        }
                    </Upload>


                </Col>
                <Col md={8} sm={8} xs={8} className='d-flex align-items-center'>

                    <Input classes="mt-2 mb-3" placeholder="Team Name" value={name} onChange={handleChangeName} />

                </Col>
            </Row>


            <br></br>

            <div className='d-flex flex-row align-items-center'>
                <h5 className='m-0' style={{ minHeight: "32px", display: "flex", alignItems: "center" }}> Team Members</h5>
                {!age_group ? "" :
                    <div className={`${cssStyles.blueGradiant} fs-8 px-3 py-1 ms-3 rounded-4`}>
                        {age_group}
                    </div>
                }
            </div>

            <br></br>

            {
                team_members.map((member, index) => (
                    <TeamMember data={member} key={index} />
                ))
            }


            <Button
                classes={`${cssStyles.cardBtn} w-100 mt-2`}
                text="Create"
                disabled={!selectedPlayer.length}
                onClick={handleSubmit}
                isSubmitted={isSubmitted}
            >
            </Button>

            <br></br>

        </div >
    )
}

export default CreateTeamCard
