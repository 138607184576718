import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseRoute } from "../../Helper";
import { WEB_ROUTES } from "../../constants";
import _ from "lodash";

function ProtectedRoute({ children }) {

    const { isLoggedIn } = useSelector(state => state.default);

    return (
        _.isUndefined(isLoggedIn) ? <></> :
            !isLoggedIn ? <Navigate to={baseRoute(WEB_ROUTES.ACCOUNT)} replace={true} /> : children
    );
}

export default ProtectedRoute;