import React, { useMemo, useState } from "react";
import cssStyles from "./index.module.css";
// import filterStyles from "./filter.module.css";
import { ROUTES } from "./Route.js";

import _ from "lodash";
import { Card, Col, Image, Row, Stack } from "react-bootstrap";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import CustomBadge from "../../Components/Badges/CustomBadge.js";
import TimeBadge from "../../Components/Badges/TimeBadge.js";
import { Button, Description, EmptyContainer, Loader, SubTitle, Title } from "../../Components/CustomComponents";
import { CardTitle } from "../../Components/CustomComponents/index.js";
// import TeamLogos from "../../Components/TeamLogos/TeamLogos.js";
import { TournamentAPI } from "../../Api/Tournament.js";
import FilterModal from "../../Components/Modals/FilterModal.js";
import { Toast } from "../../Hooks/useToast.js";
import { setFilterModal } from "../../Features/DefaultReducer.js";
import moment from "moment";



export default function TournamentScreen({ setPage }) {
  const tournamentAPI = TournamentAPI();

  const dispatch = useDispatch()
  const user_type = useSelector(state => state.default.route)
  const isFilterModal = useSelector(state => state.default.isFilterModal)

  const INITIAL_FILTER_DATA = useMemo(() => {
    return {
      selectedSport: "",
      selectedAge: "",
      startDate: ""
    }
  }, [])
  const [filterData, setFilterData] = useState(INITIAL_FILTER_DATA)


  const { data, isError, isLoading } = useQuery({
    queryKey: [user_type + 'tournament/schedule'],
    queryFn: async () => await tournamentAPI.get(),
    select: (data) => data.data,
    onSuccess: (data) => {
      setTournaments(data)
      setFilterData(INITIAL_FILTER_DATA)
    },
    onError: (err) => Toast(err.message, 'error', false),
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })

  const [tournaments, setTournaments] = useState(data ?? []);


  const handleViewTournament = (index) => {
    const record = tournaments[index]
    setPage(ROUTES.VIEW_TOURNAMENT, { tournamentId: record.slug, details: record });
  };


  const handleFilterModalClose = () => {
    dispatch(setFilterModal(false))
  }


  const handleFilterApplied = () => {
    if (!_.isEmpty(data)) {
      setTournaments(data.filter(item => {
        if (filterData.selectedSport && (item.sports_type === filterData.selectedSport)) return true;
        if (filterData.selectedAge && (item.age_groups.includes(filterData.selectedAge))) return true;

        if (filterData.startDate) {
          const selected_date = moment(filterData.startDate).format('YYYY-MM-DD')
          const tournamentStartDate = moment(item.start_date).format('YYYY-MM-DD')
          console.log(selected_date, tournamentStartDate, moment(tournamentStartDate).isSame(selected_date, "day"))
          if (moment(tournamentStartDate).isSame(moment(selected_date))) return true
        }

        return false;
      })
      )
    }
    handleFilterModalClose()
  }

  const handleClearFilter = () => {
    setTournaments(data);
    setFilterData(INITIAL_FILTER_DATA);
    handleFilterModalClose()
  }

  return (
    <div className={cssStyles.tournament}>
      {isFilterModal ? <FilterModal handleClose={handleFilterModalClose} data={filterData} setData={setFilterData} handleSubmit={handleFilterApplied} handleClear={handleClearFilter} /> : ""}

      <Title text="Tournaments" classes="mb-2" />
      <Description
        text="Continue with Google, Apple or Facebook, you agree to Prime Time Tournaments Terms and Conditions & Privacy Policy"
        classes="m-2 fs-8"
      />

      {isLoading ? <Loader /> :
        (isError || _.isEmpty(tournaments)) ? <EmptyContainer component={<SubTitle text="Tournaments not found" classes="fs-5 fw-lighter" />} /> :
          <Row className="g-4">
            {(tournaments ?? []).map(
              (
                record,
                idx // todo:
              ) => (
                <Col key={idx} lg={6}>
                  <div
                    className={cssStyles.cardStyle}
                    style={{ backgroundImage: `url(${record.media_url})` }} >
                    <Row>
                      <Col
                        xs={2}
                        className="d-flex"
                        style={{ alignItems: "start", justifyContent: "center" }}
                      >
                        <Stack
                          direction="horizontal"
                          gap={1}
                          style={{ alignItems: "start" }}
                        >
                          <Image
                            src={record.thumbnail_url}
                            roundedCircle
                            className={cssStyles.avatarImage}
                          />
                        </Stack>
                      </Col>

                      <Col xs={10}>
                        <Row>
                          <Col
                            xxl={{ order: 1, span: 8 }}
                            xl={{ order: 1, span: 7 }}
                            lg={{ order: 2, span: 12 }}
                            md={{ order: 1, span: 9 }}
                            sm={{ order: 1, span: 8 }}
                            xs={{ order: 2, span: 12 }}
                          >
                            <CardTitle
                              text={record.name}
                              classes="mt-1 fs-3 lc-1"
                            />

                            <div className="d-flex">
                              <span className="me-1">
                                <FaMapMarkerAlt />
                              </span>
                              <Card.Subtitle className="mb-2 lc-1">
                                {record.location}
                              </Card.Subtitle>
                            </div>
                          </Col>
                          <Col
                            xxl={{ order: 2, span: 4 }}
                            xl={{ order: 2, span: 5 }}
                            lg={{ order: 1, span: 12 }}
                            md={{ order: 2, span: 3 }}
                            sm={{ order: 2, span: 2 }}
                            xs={{ order: 1, span: 2 }}
                            className="d-flex"
                            style={{
                              alignItems: "start",
                            }}
                          >
                            <TimeBadge time={record.start_date} />
                          </Col>
                        </Row>
                        <div>
                          <Stack direction="horizontal" gap={2}>
                            <CustomBadge text={record.sports_type} />
                            {/* <CustomBadge text="16 Teams" /> */}
                          </Stack>
                        </div>

                        {/* <div className="mt-3">
                      <TeamLogos />
                    </div> */}
                      </Col>
                    </Row>

                    <Button
                      classes={`${cssStyles.cardBtn} mt-4`}
                      text="View Tournament Details"
                      onClick={() => handleViewTournament(idx)}
                    >
                      View Tournament Details
                    </Button>

                  </div>
                </Col>
              )
            )}
          </Row>
      }
    </div >
  );
}
