import React from "react";

import styles from "./styles/Layout.module.scss"
import { Col, Row } from "react-bootstrap";
import ChatThreads from "./ChatThreads";
import Inbox from "./Inbox";
import { useChatContext } from "./Context/ChatContext";


export default function DesktopLayout() {
    const { setChatRoom } = useChatContext();
    return (
        <div className={styles.Layout}>
            <Row className={styles.row}>
                <Col
                    className={styles.col}
                    md={4}
                >
                    <ChatThreads setUser={setChatRoom} />
                </Col>
                <Col
                    className={styles.col}
                    md={8}
                >
                    <Inbox setUser={setChatRoom} />
                </Col>

            </Row>

        </div>
    )
}