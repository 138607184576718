import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { Description, SubTitle } from '../../Components/CustomComponents';

const RulesContent = ({ text = '' }) => {
  // const lists = [
  //   'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  //   'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  //   'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
  // ];

  return (

    <div>
      <Row>
        <Col md={12} >
          <SubTitle
            text='Tournament Rules'
            classes='mb-4 mt-2'
          />

          <Description
            text={text}
            classes='my-3'
          />



          {/* <ul >
            {
              lists.map((item, index) => <li key={index} className={`mt-2 mb-2 fs-7`}>{item}</li>)
            }
          </ul> */}

        </Col>
      </Row>
    </div>
  );
}

export default RulesContent;
