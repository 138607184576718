import React, { useState } from "react";

import styles from "../index.module.scss"

import { Button, CardTitle, Description, SubTitle } from "../../../Components/CustomComponents";
import { FaMapMarkerAlt } from "react-icons/fa";
import { CiShare2 } from "react-icons/ci";
import { useQueryClient } from "react-query";

export default function TicketDetails({ ticket_slug = '' }) {
    const queryClient = useQueryClient();
    const [data] = useState(queryClient.getQueryData(['user/ticket/' + ticket_slug]))
    return (
        <div className={styles.teamInfoContainer}>

            <div className={styles.headerContainer}>
                <SubTitle
                    text={'E-Ticket View'}
                />
            </div>

            <Description
                text='Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
                classes='fs-8 mb-4'
            />
            <div className={styles.modalContainer} style={{ margin: 'auto' }}>


                <img
                    src={data.thumbnail_url}
                    alt='Tournament Profile'
                    className={styles.image}
                />
                <CardTitle
                    text={data.name}
                    classes='mt-3 fs-5'
                />
                <div className={styles.location}>
                    <span className={styles.icon}><FaMapMarkerAlt /></span>
                    <Description
                        text={data.location}
                        classes='fs-9 lc-1'
                    />
                </div>

                <hr className='' ></hr>

                <div className='mt-5 mb-3'>
                    <img
                        src='/images/qr_image.png'
                        alt='QR code '
                        className={styles.qrImage}
                    />
                </div>
                <CardTitle
                    text='Gladiators'
                    classes='mt-1 fs-4'
                />
                {/* <Description
                    text='5 Members'
                    classes='mt-1 fs-7'
                /> */}
                <Description
                    text='Pass ID: WASQL7H'
                    classes='mt-1 fs-7'
                />

                <div className='mt-5 d-flex w-100'>
                    <button className={`${styles.shareButton} `}>
                        <CiShare2 className='mx-2' />
                        Share
                    </button>

                    <Button
                        classes={`mx-1 d-flex align-item-center justify-content-center `}
                        text="Continue"
                    // onClick={handleModalClose}
                    ></Button>

                </div>

            </div>
        </div>

    )
}