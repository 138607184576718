import React from "react";

import styles from "./styles/Layout.module.scss"
import Inbox from "./Inbox";
import ChatThreads from "./ChatThreads";
import { useChatContext } from "./Context/ChatContext";

export default function MobileLayout() {
    const { chatRoom, setChatRoom } = useChatContext();
    return (
        <div className={styles.Layout}>
            {chatRoom ?
                <Inbox
                    isMobile={true}
                    setUser={setChatRoom}
                /> :
                <ChatThreads
                    setUser={setChatRoom}
                />
            }

        </div>
    )
}