import React, { useState } from "react";

import styles from "../index.module.scss";

import { TeamAPI } from "../../../Api/Team";
import { CardTitle, Description } from "../../../Components/CustomComponents";
import { Toast } from "../../../Hooks/useToast";


import { Badge, Spinner } from "react-bootstrap";
import { HiPlus } from "react-icons/hi";
import { useMutation, useQueryClient } from "react-query";
import _ from "lodash";
import { FaBan } from "react-icons/fa";



export default function TeamMember({ team_slug, setDetails, handleOpen }) {
    const queryClient = useQueryClient()
    const [data, setData] = useState(queryClient.getQueryData(['team/' + team_slug]) ?? {})

    const teamAPI = TeamAPI();

    const [isDeleted, setIsDeleted] = useState(undefined);

    const removePlayerMutation = useMutation(teamAPI.removePlayer, {
        onSuccess: (__, { player_slug }) => {

            queryClient.removeQueries({
                queryKey: ['age-group/' + data?.group_type_slug + '/team/unregister']
            })

            if (data.is_registered) {
                setDetails(details => {
                    return {
                        ...details,
                        member_count: details?.member_count - 1,
                        team_members: details?.team_members.filter(item => item.slug !== player_slug)
                    }
                })
            }

            queryClient.setQueryData(['team'], (data) => {
                return {
                    ...data,
                    data: data?.data.map(item => {
                        if (item.slug !== team_slug) return item;
                        return {
                            ...item,
                            member_count: item?.member_count - 1,
                            team_members: item?.team_members.filter(item => item.slug !== player_slug)
                        }
                    })
                }
            })

            queryClient.setQueryData(['user/player'], (player_data) => {
                return {
                    ...player_data,
                    data: player_data?.data.map(item => {
                        if (item.slug !== player_slug) return item
                        return {
                            ...item,
                            is_registered: false
                        }
                    })
                }
            })

            setData(data => {
                return {
                    ...data,
                    team_members: data?.team_members.filter(item => item.slug !== player_slug)
                }
            })


            setIsDeleted(undefined)
        },
        onError: (err) => {
            Toast(err.message, 'error', false)
            setIsDeleted(undefined)
        }
    });




    const handleRemovePlayer = (player_slug) => {
        if (!_.isUndefined(isDeleted)) return;

        setIsDeleted(player_slug);
        removePlayerMutation.mutate({ team_slug, player_slug });
    }



    return (
        <>
            <div className={'d-flex flex-row w-100 align-items-center'}>
                <div className="d-flex flex-column">
                    <CardTitle
                        text='Members' />
                    <Description
                        text='All the team members are listed below'
                        classes='opacity-75 fs-9'
                    />
                </div>
                <div className='d-flex flex-row ms-auto align-items-center'>
                    <button
                        className={`d-flex flex-row align-items-center bg rounded border-0 h-fit py-1 px-3`}
                        style={{ height: "fit-content" }}
                        onClick={() => handleOpen(true)}
                    >
                        <span className={styles.icon}><HiPlus /></span>
                        <p className='d-flex fs-7 fw-medium m-0'>
                            Add Kid
                        </p>
                    </button>
                </div>

            </div>
            <div className={`${styles.userContainer} my-3`}>
                <img
                    src={data.coach_image_url}
                    alt='Coach Profile'
                    className={styles.image}
                />
                <Description
                    text={data.coach_name}
                    classes='ps-2 lc-1 fs-8'
                />
                <span className={styles.coachBadge}>Coach</span>

            </div>
            <div>
                {
                    (data?.team_members ?? [])?.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={`${styles.userContainer} my-3 `}>
                                <img
                                    src={item.image_url}
                                    alt='User Profile'
                                    className={styles.image}
                                />
                                <Description
                                    text={item.name}
                                    classes='ps-2 lc-1 fs-8 mw-25'
                                />
                                <div className="d-flex flex-row ms-auto">
                                    {item.is_banned ? <FaBan style={{ color: "#E95B5B", fontSize: "2rem", marginRight: "10px" }} /> : ""}
                                    {
                                        (_.isUndefined(isDeleted) || (isDeleted !== item.slug)) ?
                                            <Badge
                                                className={`p-2 fs-9 cursor-pointer`}
                                                bg=""
                                                pill
                                                style={{ fontWeight: 'normal', background: '#E95B5B' }}
                                                onClick={() => handleRemovePlayer(item.slug)}
                                            >
                                                Remove
                                            </Badge>
                                            :
                                            <Badge
                                                className=" fs-8"
                                                bg=""
                                                pill
                                                style={{
                                                    padding: "0 8px",
                                                    fontWeight: 'normal',
                                                    background: '#E95B5B',
                                                    marginLeft: 'auto',
                                                    minWidth: "60px",
                                                    minHeight: "30px",
                                                    textAlign: "center",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <Spinner style={{ fontSize: "6px" }} size="sm" />
                                            </Badge>
                                    }
                                </div>

                            </div>)
                    }
                    )
                }
            </div>
        </>
    )
}