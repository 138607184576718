import _ from "lodash";
import React, { useState } from 'react';

import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileAPI } from '../../Api/Profile';
import { Button, Description, InputCustom, SubTitle } from '../../Components/CustomComponents';
import { IMAGE_TYPES } from '../../constants';
import { setUser } from '../../Features/DefaultReducer';
import { Toast } from '../../Hooks/useToast';
import styles from './styles/EditProfile.module.scss';


export default function EditProfile() {
    const profileAPI = ProfileAPI()
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.default);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [data, setData] = useState({
        firstname: user.firstname ?? "",
        lastname: user.lastname ?? "",
        image_url: user.image_url ?? ""
    })

    const [error, setError] = useState({
        firstname: "",
        lastname: "",
    })

    const handleInputChange = (name, value) => {
        if (isSubmitted) return;
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
        setError(prev => {
            return {
                ...prev,
                [name]: !value ? `${name} should not be empty` : ""
            }
        })
    }
    const handleImageChange = (e) => {
        if (isSubmitted) return;
        setData(prev => {
            return {
                ...prev,
                image_url: e.target.files[0]
            }
        })
    }
    const handleSubmit = async () => {
        if (!data.firstname || !data.lastname || isSubmitted) return;

        try {
            setIsSubmitted(true);
            const formData = new FormData();
            formData.append('firstname', data.firstname)
            formData.append('lastname', data.lastname)
            if (_.isObject(data.image_url)) {
                formData.append('image_url', data.image_url)
            }

            const response = await profileAPI.update(formData);
            dispatch(setUser(response))
            Toast('Profile updated successfully', 'success', false)
        }
        catch (err) {
            Toast(err.message, 'error', false)
        }
        finally {
            setIsSubmitted(false)
        }
    }

    return (
        <div className={styles.EditProfile}>
            <SubTitle
                text='Edit Profile'
            />
            <Description
                text='Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
                classes='fs-8 mb-4'
            />
            <div className={styles.form}>
                <div className={styles.imageContainer}>
                    <input type="file" id="actual-btn" hidden className={styles.fileChooseInput} onChange={handleImageChange} multiple={false} accept={IMAGE_TYPES} />
                    <label htmlFor='actual-btn' className={styles.label}>
                        {_.isObject(data.image_url) ?
                            <img
                                src={URL.createObjectURL(data.image_url)}
                                alt='User Profile'
                                className={styles.userImage}
                            /> :
                            <img
                                src={data.image_url ?? '/images/profile.png'}
                                alt='User Profile'
                                className={styles.userImage}
                            />
                        }
                    </label>
                </div>
                <SubTitle
                    text={user.firstname + " " + user.lastname}
                    classes='fs-5 mt-2'
                />
                <Description
                    text={user.email}
                    classes='fs-8 my-1'
                />
                <Description
                    text={user.mobile_no}
                    classes='fs-8 mb-3'
                />
                <InputCustom
                    name="firstname"
                    placeholder='First name'
                    value={data.firstname}
                    onChange={handleInputChange}
                />
                {error.firstname ? <p className='w-100 text-danger fs-7 fw-lighter text-left ps-1 mb-3'>{error.firstname}</p> : ""}

                <InputCustom
                    name="lastname"
                    placeholder='Last name'
                    value={data.lastname}
                    onChange={handleInputChange}
                />
                {error.lastname ? <p className='w-100 text-danger fs-7 fw-lighter text-left ps-1 mb-3'>{error.lastname}</p> : ""}

                <InputCustom
                    placeholder='Email Address'
                    value={user.email}
                    disabled={true}
                />

                <Button
                    text={<div className='d-flex flex-row align-items-center justify-content-center'>Save and Continue {isSubmitted ? <Spinner size='sm' color='white' className='ms-3' /> : ""}</div>}
                    classes='mt-5 mb-2 fs-6'
                    onClick={handleSubmit}
                />

            </div>
        </div>
    )
}