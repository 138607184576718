import { useSelector } from "react-redux";
import { Axios } from "../Hooks/useAxiosInstance";


export const SettingAPI = () => {

    const { route, user } = useSelector(state => state.default)

    const apis = ({ route, api_token }) => ({
        getPrivacyPolicy: async () => await getPrivacyPolicyRecord(api_token),
        getTermsAndCondition: async () => await getTermsAndConditionRecord(api_token),
    })

    async function getPrivacyPolicyRecord(api_token) {
        const url = "user/setting/privacy-policy";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getTermsAndConditionRecord(api_token) {
        const url = "user/setting/terms-and-condition";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    return apis({ route: route, api_token: user.api_token });
}

