import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import TournamentBanner from "../../Components/Cards/TournamentBanner";
import RegistrationCard from "../../Components/Cards/RegistrationCard";
import cssStyles from "./index.module.css";
import Tabs from "../../Components/Tabs/Tabs";
import _ from "lodash";
import { Button, Loader } from "../../Components/CustomComponents";
import DetailsContent from "./DetailsContent";
import TeamsContent from "./TeamsContent";
import TablesContent from "./TablesContent";
import RulesContent from "./RulesContent";
import { ROUTES } from "./Route";
import TicketPriceCard from "../../Components/Cards/TicketPriceCard";
import CheckOutSummary from "./Components/CheckOutSummary";
import ModalContainer from "../../Components/ModalContainer";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { Toast } from "../../Hooks/useToast";
import Ticket from "../../Components/Ticket";
import RegisteredTeam from "../../Components/Cards/RegisteredTeam";
import RegisterTeam from "./Components/RegisterTeam";

import { TournamentAPI } from "../../Api/Tournament";
import { TicketAPI } from "../../Api/Ticket";
import { useMutation, useQueryClient } from "react-query";

const ViewTournamentScreen = ({ setPage, tournamentId = "", details = {} }) => {
  const {
    isViewer: IS_VIEWER,
    isCoach: IS_COACH,
    route: base_route,
    user
  } = useSelector((state) => state.default);

  const ticketAPI = TicketAPI();
  const tournamentAPI = TournamentAPI();
  const queryClient = useQueryClient();
  const QUERY_KEY = base_route + "tournament/" + tournamentId;

  const [key, setKey] = useState("details");
  const [isShowRegisterModal, setIsShowRegisterModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const array = useMemo(
    () => [
      {
        value: "details",
        label: "Details",
      },
      {
        value: "tables",
        label: "Tables",
      },
      {
        value: "teams",
        label: "Teams",
      },
      {
        value: "rules",
        label: "Rules",
      },
    ],
    []
  );

  const { data, isError, isFetchedAfterMount } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => await tournamentAPI.getByID(tournamentId),
    select: (data) => data.data,
    onError: (err) => Toast(err.message, "error", false),
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    initialData: details,
  });

  const ticketPostMutation = useMutation(ticketAPI.post, {
    onSuccess: () => {
      const prev_data = queryClient.getQueryData([QUERY_KEY]);
      queryClient.setQueryData([QUERY_KEY], {
        ...prev_data,
        data: { ...prev_data?.data, is_ticket_purchased: true },
      });
      queryClient.invalidateQueries({
        queryKey: ["user/ticket"],
        refetchActive: true,
        refetchInactive: true,
      });
      setIsSubmitted(false);
    },
    onError: (err) => {
      Toast(err.message, "error", false);
      setIsSubmitted(false);
    },
  });

  const showGetRegisterModal = () => {
    setIsShowRegisterModal(true);
  };

  const hideGetRegisterModal = () => {
    setIsShowRegisterModal(false);
  };

  const handleBackButtonClck = () => {
    setPage(ROUTES.TOURNAMENT);
  };

  const handleCheckOut = (card) => {
    const payload = {};
    payload.stripe_payment_id = card;
    payload.tournament_slug = data.slug;
    ticketPostMutation.mutate(payload);
  };

  const tabContent = () => {
    switch (key) {
      case "details":
        return <DetailsContent text={data?.description} />;
      case "tables":
        return <TablesContent groups={data?.age_groups} />;
      case "teams":
        return <TeamsContent groups={data?.age_groups} />;
      case "rules":
        return <RulesContent text={data?.rules} />;

      default:
        return <DetailsContent />;
    }
  };
  return (
    <div className={cssStyles.ViewTournament}>
      {isShowRegisterModal ? (
        <ModalContainer handleClose={hideGetRegisterModal}>
          <RegisterTeam
            data={data}
            handleClose={hideGetRegisterModal}
            TOURNAMENT_DETAIL_QUERY_KEY={QUERY_KEY}
          />
        </ModalContainer>
      ) : (
        ""
      )}

      <Row>
        <Col md={12} lg={8}>
          <TournamentBanner
            QUERY_KEY={QUERY_KEY}
            hasBackButton={true}
            handleBackButtonClck={handleBackButtonClck}
            live={data?.is_live}
          />
        </Col>
        <Col md={12} lg={4}>
          <div className={cssStyles.topMargins}>
            {IS_VIEWER ? (
              <TicketPriceCard QUERY_KEY={QUERY_KEY} />
            ) : (
              <RegistrationCard QUERY_KEY={QUERY_KEY} />
            )}
          </div>
        </Col>
      </Row>
      {!isFetchedAfterMount ? (
        <Loader />
      ) : isError || _.isEmpty(data) ? (
        ""
      ) : (
        <div className="mt-3">
          <Row>
            <Col
              xs={{ order: 2, span: 12 }}
              sm={{ order: 2, span: 12 }}
              md={{ order: 2, span: 12 }}
              lg={{ order: 1, span: 8 }}
              xl={{ order: 1, span: 8 }}
              xxl={{ order: 1, span: 8 }}
            >
              <div className={cssStyles.beforeTabs}>
                <Tabs tabs={array} current={key} onChange={setKey} />
              </div>

              <div style={{ color: "white" }}>{tabContent()}</div>
            </Col>
            <Col
              xs={{ order: 1, span: 12 }}
              sm={{ order: 1, span: 12 }}
              md={{ order: 1, span: 12 }}
              lg={{ order: 2, span: 4 }}
              xl={{ order: 2, span: 4 }}
              xxl={{ order: 2, span: 4 }}
            >
              <Row>
                <Col>
                  {IS_VIEWER ? (
                    data?.is_ticket_purchased ? (
                      <Ticket data={data} />
                    ) : (
                      <CheckOutSummary
                        data={data}
                        isSubmitted={isSubmitted}
                        setIsSubmitted={setIsSubmitted}
                        handleCheckOutSubmit={handleCheckOut}
                      />
                    )
                  ) : //   isShowCheckOut ? (
                    // <CheckOutSummary />
                    // ) : (
                    // <Button
                    //   classes={`${cssStyles.panelBtn} ${cssStyles.cardBtn}`}
                    //   text="Buy Ticket"
                    //   onClick={handleBuyTicket}
                    // ></Button>
                    // )
                    IS_COACH ? (
                      <>
                        {(data.registered_team ?? []).map((item, index) => {
                          const payload = {};
                          payload.team_name = item.name;
                          payload.team_image_url = item.image_url;
                          payload.team_age_group = item.age_group;
                          payload.member_count = item.member_count;
                          payload.tournament_image_url = data.thumbnail_url;
                          payload.tournament_name = data.name;
                          payload.tournament_location = data.location;
                          return (
                            <RegisteredTeam
                              classes="mb-3"
                              data={payload}
                              key={index}
                            />
                          );
                        })}
                        {!user.is_banned ?
                          <Button
                            classes={`${cssStyles.panelBtn} ${cssStyles.cardBtn} `}
                            text="Get Registered"
                            onClick={showGetRegisterModal}
                          ></Button>
                          : ""}
                      </>
                    ) : (
                      ""
                    )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ViewTournamentScreen;
