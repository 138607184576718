import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import TeamAgeCard from "../../Components/Cards/TeamAgeCard";
import cssStyles from "./index.module.css";

import ModalContainer from "../../Components/ModalContainer";
import TeamDetails from "./Components/TeamDetails";
import _ from "lodash";

const TeamsTabContent = ({ data = [] }) => {
  const [showViewTeamModal, setShowViewTeamModal] = useState(undefined);

  const handleShowViewTeam = (slug) => {
    setShowViewTeamModal(slug);
  };

  const hideViewTeamModal = () => {
    setShowViewTeamModal(undefined);
  };

  return (
    <>
      {!_.isUndefined(showViewTeamModal) ? (
        <ModalContainer handleClose={hideViewTeamModal}>
          <TeamDetails slug={showViewTeamModal} hideViewTeamModal={hideViewTeamModal} />
        </ModalContainer>
      ) : (
        ""
      )}
      <Row className={cssStyles.teamCardContainer}>
        {data.map((item, index) => (
          <Col className="p-3" key={index} md={6} sm={12}>
            <TeamAgeCard
              data={item}
              sNo={index}
              handleShowViewTeam={() => handleShowViewTeam(item.slug)}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default TeamsTabContent;
