import React, { useEffect } from 'react';

import styles from "./styles/Dashboard.module.scss"

import { Description, EmptyContainer, Loader, SubTitle } from '../../Components/CustomComponents';
import { Row, Col } from 'react-bootstrap';
import MatchCard from '../../Components/Cards/MatchCard';
import TournamentCard from '../../Components/Cards/TournamentCard';
import { ROUTES } from './constants';
import { TournamentAPI } from '../../Api/Tournament';
import { Toast } from '../../Hooks/useToast';
import { useQueries, useQueryClient } from 'react-query';
import _ from 'lodash';
import { MatchAPI } from '../../Api/Match';
import { useSocket } from '../../Context/SocketContext';

export default function Dashboard({ setPage }) {
    const tournamentAPI = TournamentAPI()
    const matchAPI = MatchAPI()
    const queryClient = useQueryClient()
    const socket = useSocket()
    const QUERY_PAYLOAD = {
        select: (data) => data.data,
        onError: (err) => Toast(err.message, 'error', false),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    }
    const [matchQuery, tournamentQuery] = useQueries([
        {
            queryKey: ['match/live'],
            queryFn: async () => await matchAPI.getLive(),
            ...QUERY_PAYLOAD
        },
        {
            queryKey: ['tournament/live'],
            queryFn: async () => await tournamentAPI.getLive(),
            onSuccess: (data) => {
                data.forEach(item => queryClient.setQueryData(['tournament/' + item.slug + '/live'], item));
            },
            ...QUERY_PAYLOAD
        }
    ])

    const { data: matches, isError: matchError, isLoading: matchLoading } = matchQuery
    const { data: tournaments, isError: tournamentError, isLoading: tournamentLoading } = tournamentQuery

    useEffect(() => {
        socket.on('MATCH_END', (data) => {
            const prev = queryClient.getQueryData(['match/live'])
            queryClient.setQueryData(['match/live'], { ...prev, data: (prev.data ?? []).filter(item => item.slug !== data.match_slug) })
        })

        socket.on('MATCH_START', (data) => {
            const prev = queryClient.getQueryData(['match/live'])
            const updated_data = (prev?.data ?? []).map(item => (item.slug !== data.match_slug) ? item : { ...item, is_started: true, score: data.score })
            queryClient.setQueryData(['match/live'], { ...prev, data: updated_data })
        })

        return () => {
            socket.dispose('MATCH_END')
            socket.dispose('MATCH_START')
        }
    }, [])


    const handleViewLiveMatches = () => {
        setPage(ROUTES.LIVE_MATCHES)
    }

    const handleViewTournament = () => {
        setPage(ROUTES.TOURNAMENT_LISTING)
    }

    const handleTournamentClick = (data) => {
        setPage(ROUTES.TOURNAMENTS, data)
    }

    return (
        <div className={styles.Dashboard}>

            <div className={`${styles.section} mb-5`}>

                <div
                    className={styles.headerContainer}
                >
                    <div className={styles.titleContainer}>

                        <SubTitle
                            text={'Live Matches'}
                        />
                        <Description
                            text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}
                            classes='mt-1 fs-8'
                        />

                    </div>
                    {(matchLoading || matchError || _.isEmpty(matches)) ? "" :
                        <button
                            className={styles.viewButton}
                            onClick={handleViewLiveMatches}
                        >
                            View All
                        </button>
                    }
                </div>
                <Row
                    className={`${styles.matchRow} w-100 mt-2`}
                    gutter={[0, 24]}
                >
                    {matchLoading ? <Loader /> :
                        (matchError || _.isEmpty(matches)) ?
                            <EmptyContainer component={<SubTitle text='No live match yet' classes='fs-5 fw-lighter' />} /> :
                            matches.map((match, index) => <Col
                                className={`${styles.matchCard} mt-2 mb-2`}
                                lg={3} md={4} sm={6}
                            >
                                <MatchCard key={index} data={match} />
                            </Col>)
                    }
                </Row>
            </div>
            <div className={styles.section}>

                <div
                    className={styles.headerContainer}
                >
                    <div className={styles.titleContainer}>

                        <SubTitle
                            text={'Live Tournaments'}
                        />
                        <Description
                            text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}
                            classes='mt-1 fs-8'
                        />

                    </div>
                    {(tournamentLoading || tournamentError || _.isEmpty(tournaments)) ? "" :
                        <button
                            className={styles.viewButton}
                            onClick={handleViewTournament}
                        >
                            View All
                        </button>
                    }
                </div>
                <Row
                    className={`${styles.matchRow} w-100 mt-2 mb-2`}
                    gutter={[0, 24]}
                >
                    {tournamentLoading ? <Loader /> :
                        (tournamentError || _.isEmpty(tournaments)) ?
                            <EmptyContainer component={<SubTitle text='No live tournament yet' classes='fs-5 fw-lighter' />} /> :
                            tournaments.map(t_record => <Col
                                className={`mt-2 mb-2`}
                                lg={4} md={6} sm={6}
                            >
                                <TournamentCard data={t_record} onClick={() => handleTournamentClick(t_record)} />
                            </Col>
                            )}
                </Row>
            </div>
        </div>
    )
}

