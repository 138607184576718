import React, { useState } from 'react'

import { ROUTES } from "./Route";

import Login from '../Account/Login';
// import ViewTournamentScreen from './ViewTournament';
import ProfileScreen from './profileScreen';
import MagicBoxScreen from './MagicBox';

export default function Profile() {

    const [page, setPage] = useState(ROUTES.PROFILE)

    switch (page) {
        case ROUTES.PROFILE:
            return <ProfileScreen setPage={setPage} />
        case ROUTES.MAGIC_BOX:
            return <MagicBoxScreen setPage={setPage} />

        default:
            return <ProfileScreen setPage={setPage} />
    }


}
