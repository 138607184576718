import React from "react";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { Button, Description, Input, Title } from "../../Components/CustomComponents";
import { ACCOUNT_ENUM } from "./Route";


export default function ForgotPassword({ setPage }) {

    const handleBackButtonClick = () => {
        setPage(ACCOUNT_ENUM.LOGIN)
    }

    const handleSubmit = () => {
        setPage(ACCOUNT_ENUM.OTP)
    }

    return (
        <AccountLayout
            hasBackButton={true}
            handleBackButton={handleBackButtonClick}
        >
            <Title text="Forgot Password" classes="mb-2" />
            <Description
                text="In order to reset your password you need to enter your registered phone number."
                classes="text-center fs-7"
            />
            <Input
                placeholder="Email Address"
                classes="mt-4 mb-5"
            />
            <Button
                text='Continue'
                classes="mt-3 mb-2"
                onClick={handleSubmit}
            />

        </AccountLayout>
    )
}