import React from "react";

import styles from "./styles/TournamentBanner.module.scss";

import BorderedCard from "./BorderedCard";
import { CardTitle, Description } from "../CustomComponents";

import { FaMapMarkerAlt } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { useQueryClient } from "react-query";
import { transformData } from "../../Helper";

export default function TournamentBanner({
  QUERY_KEY,
  hasBackButton = false,
  live = false,
  handleBackButtonClck = () => { },
}) {

  const queryClient = useQueryClient();
  const data = transformData(queryClient.getQueryData(QUERY_KEY))

  return (
    <BorderedCard>
      <div className={styles.TournamentBanner}>
        {hasBackButton ? (
          <span className={styles.backButton} onClick={handleBackButtonClck}>
            <IoIosArrowBack />
          </span>
        ) : (
          ""
        )}
        <img
          src={data?.media_url}
          alt="Card Banner"
          className={styles.bannerImage}
        />
        <div className={styles.shadowContainer}></div>
        <div className={styles.content}>
          <img
            src={data?.thumbnail_url}
            alt="Tournament Logo"
            className={styles.logo}
          />
          <div className={styles.info}>
            <div className={styles.titleContainer}>
              <CardTitle text={data?.name} classes="lc-1 fs-3" />
              {live ? <span className={styles.liveBadge}>Live</span> : ""}
            </div>
            <div className={styles.descriptionContainer}>
              <span className={styles.mapIcon}>
                <FaMapMarkerAlt />
              </span>
              <Description
                text={data?.location}
                classes="fs-9 lc-1"
              />
            </div>
          </div>
        </div>
      </div>
    </BorderedCard>
  );
}
