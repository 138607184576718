import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../Components/CustomComponents";
import { useSelector } from "react-redux";
import { ConfirmPayment } from "../../../Api/Payment";
import { Toast } from "../../../Hooks/useToast";

const PaymentFormModal = ({
  handleCheckout = () => { }
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loadButton, setLoadButton] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({ mode: "onChange" });
  const { user } = useSelector((state) => state.default);
  const [disabled, setDisabled] = useState(false);
  const confirmPayment = ConfirmPayment();
  const onSubmit = async (event) => {
    setLoadButton(true);
    if (!stripe || !elements) {
      return;
    }
    try {
      const { token, error } = await stripe.createToken(
        elements.getElement(CardElement)
      );
      const tokenId = token.id;
      handleCheckout(tokenId)
    } catch (error) {
      Toast(error?.message, "error", false);
      setLoadButton(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="form-payment">
      <Row>
        <Col xs={12}>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  iconColor: "#c4f0ff",
                  color: "#fff",
                  fontWeight: "500",
                  fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                  fontSize: "16px",
                  fontSmoothing: "antialiased",
                  ":-webkit-autofill": {
                    color: "#fce883",
                  },
                  "::placeholder": {
                    color: "#87BBFD",
                  },
                },
                invalid: {
                  iconColor: "#FFC7EE",
                  color: "#FFC7EE",
                },
              },
            }}
          />
        </Col>
        <Col xs={12} className="d-flex">
          <Button
            type="submit"
            disabled={!stripe || loadButton || !isValid}
            classes="btn-solid mt-4 ms-4 ms-auto w-100"
            style={{
              border: "1px solid #0aa7ff",
            }}
            text={"CONTINUE"}
          ></Button>
        </Col>
      </Row>
    </Form>
  );
};

export default PaymentFormModal;
