import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useSocket } from "../../../Context/SocketContext";
import { useSelector } from "react-redux";


export const ChatContext = createContext();

export function useChatContext() {
    return useContext(ChatContext)
}


export default function ChatContextProvider({ children }) {
    const socket = useSocket();
    const { isSocketConnected } = useSelector(state => state.default)

    const INITIAL_CHAT_THREAD = useMemo(() => {
        return {
            isError: false,
            isLoading: true,
            data: []
        }
    }, [])

    const INITIAL_CHAT_HISTORY = useMemo(() => {
        return {
            isError: false,
            isLoading: true,
            data: []
        }
    }, [])


    const [chatRoom, setChatRoom] = useState('')
    const [chatThread, setChatThread] = useState(INITIAL_CHAT_THREAD)
    const [chatHistory, setChatHistory] = useState(INITIAL_CHAT_HISTORY)

    useEffect(() => {
        if (isSocketConnected) {
            socket.on('getChatThreads_', (data) => {
                setChatThread(prev => {
                    return {
                        ...prev,
                        isLoading: false,
                        data: data
                    }
                })
            })

            socket.emit('_getChatThreads')
        }


        return () => {
            if (isSocketConnected) {
                socket.dispose('getChatThreads_')
            }
        }
    }, [isSocketConnected])

    useEffect(() => {
        socket.on('loadChatHistory_', (data) => {
            setChatHistory(prev => {
                return {
                    ...prev,
                    isLoading: false,
                    data: data
                }
            })

        })

        if (chatRoom) {
            socket.emit('_loadChatHistory', { chat_room_slug: chatRoom })
        }
        else {
            setChatHistory(INITIAL_CHAT_HISTORY)
        }


        return () => {
            socket.dispose('loadChatHistory_')
        }
    }, [chatRoom])


    return (
        <ChatContext.Provider value={{ chatThread, chatHistory, chatRoom, setChatRoom }}>
            {children}
        </ChatContext.Provider>
    )

}