import React from 'react'
import { Col } from 'react-bootstrap'
import cssStyles from '../index.module.css';

const TeamMember = ({ data = {} }) => {
    return (
        <div>
            <div className='p-1 rounded-4' style={{ background: '#0D0D1C', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                <Col
                    md={10}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    <img
                        className={cssStyles.teamAvatar}
                        src={data.image_url}
                        alt="Team Logo"
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', paddingRight: '10px' }} >
                        <p className=' fw-bold fs-7 lc-1 m-0 text-white'> {data.fullname} </p>
                    </div>
                </Col>
            </div>
        </div>
    )
}

export default TeamMember
