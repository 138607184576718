import React from 'react';

import styles from './styles/StaticPage.module.scss'

import { Description, Loader, SubTitle } from '../../Components/CustomComponents';
import { Toast } from '../../Hooks/useToast';
import { SettingAPI } from '../../Api/Setting';
import { useQuery } from 'react-query';

export default function TermsAndConditions() {
    const settingAPI = SettingAPI()
    const { data, isLoading } = useQuery({
        queryKey: ['setting/terms-and-condition'],
        queryFn: async () => await settingAPI.getTermsAndCondition(),
        select: (data) => data.data,
        onError: (err) => Toast(err.message, 'error', false),
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    })
    return (
        <div className={styles.StaticPage}>
            <SubTitle
                text='Terms And Conditions'
                classes='mb-4'
            />
            {isLoading ? <Loader /> :
                <Description
                    text={data.text}
                    classes='my-3'
                />

            }
        </div>
    )
}