import { useSelector } from "react-redux";
import { Axios } from "../Hooks/useAxiosInstance";


export const TicketAPI = () => {

    const { route, user } = useSelector(state => state.default)

    const apis = ({ route, api_token }) => ({
        post: async (data) => await postRecord(data, route, api_token),
        get: async () => await getRecord(route, api_token),
    })

    async function postRecord(data, route, api_token) {
        const url = route + "ticket";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.post(url, data, option);
    }

    async function getRecord(route, api_token) {
        const url = route + "ticket";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    return apis({ route: route, api_token: user.api_token });
}

