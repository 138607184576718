import React, { useState } from 'react'

import { ROUTES } from "./Route";
import TournamentScreen from './Tournament';
import ViewTournamentScreen from './ViewTournament';
import GetRegisterScreen from './GetRegister';

export default function Tournaments() {

  const [data, setData] = useState({
    page: ROUTES.TOURNAMENT,
    tournamentId: '',
    details: {}
  })

  const setPage = (route, data = {}) => {
    setData({
      page: route,
      tournamentId: data.tournamentId ?? '',
      details: data.details ?? {}
    })
  }

  switch (data.page) {
    case ROUTES.TOURNAMENT:
      return <TournamentScreen setPage={setPage} />
    case ROUTES.VIEW_TOURNAMENT:
      return <ViewTournamentScreen setPage={setPage} tournamentId={data.tournamentId} details={data.details} />
    case ROUTES.GET_REGISTER:
      return <GetRegisterScreen setPage={setPage} />

    default:
      return <TournamentScreen />
  }


}
