import { toast } from "react-toastify";



export function Toast(message, toastType, loading, closeTime = 2000, id = 100) {
    const isToastActive = toast.isActive(id);
    if (isToastActive) {
        toast.update(id, { render: message, type: toastType, isLoading: loading, autoClose: closeTime })
    }
    else {
        toast[`${toastType}`](message, { toastId: id, autoClose: closeTime, position: 'top-center' });
    }
}