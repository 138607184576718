import React, { useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';


import { WEB_ROUTES } from "./constants";
import { baseRoute } from "./Helper";
import PublicRoute from "./Components/Routes/PublicRoute";

import Account from "./Pages/Account";
import ProtectedLayout from "./Components/Layouts/ProtectedLayout";
import Dashboard from "./Pages/Dashboard";
import Tournaments from "./Pages/Tournaments";
import Messages from "./Pages/Messages";
import Setting from "./Pages/Setting";
import Profile from "./Pages/Profile";
// import PaymentMethod from "./Pages/PaymentMethod";
import ProtectedRoute from "./Components/Routes/ProtectedRoute";
import { useLocalStorage } from "./Hooks/useLocalStorage";
import RoleProtectedRoute from "./Components/Routes/RoleProtectedRoute";


function App() {
  useLocalStorage()

  const ROUTES = useMemo(() => {
    return [
      <Route exact path={baseRoute(WEB_ROUTES.ACCOUNT)} element={
        <PublicRoute >
          <Account />
        </PublicRoute>
      } />,
      <Route exact path={baseRoute(WEB_ROUTES.DASHBOARD)} element={
        <ProtectedRoute>
          <ProtectedLayout selectedRoute={0}>
            <Dashboard />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route exact path={baseRoute(WEB_ROUTES.TOURNAMENT)} element={
        <ProtectedRoute>
          <ProtectedLayout selectedRoute={1} isSettingButton={true}>
            <Tournaments />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route exact path={baseRoute(WEB_ROUTES.MESSAGE)} element={
        <ProtectedRoute>
          <RoleProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={2}>
              <Messages />
            </ProtectedLayout>
          </RoleProtectedRoute>
        </ProtectedRoute>
      } />,
      // <Route exact path={baseRoute(WEB_ROUTES.PAYMENT_METHOD)} element={
      //   <ProtectedRoute>
      //     <RoleProtectedRoute>
      //       <ProtectedLayout isSearchBar={false} selectedRoute={3}>
      //         <PaymentMethod />
      //       </ProtectedLayout>
      //     </RoleProtectedRoute>
      //   </ProtectedRoute>
      // } />,
      <Route exact path={baseRoute(WEB_ROUTES.SETTING)} element={
        <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={3}>
            <Setting />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route exact path={baseRoute(WEB_ROUTES.PROFILE)} element={
        <ProtectedRoute>
          <RoleProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={4}>
              <Profile />
            </ProtectedLayout>
          </RoleProtectedRoute>
        </ProtectedRoute>
      } />,

    ]

  }, [])

  return (
    <Router>
      <Routes>
        {ROUTES.map((item) => item)}
        <Route path={baseRoute('*')} element={<Navigate to={baseRoute(WEB_ROUTES.ACCOUNT)} />} />
      </Routes >


    </Router >
  );
}

export default App;
