import React from "react";

import styles from "./styles/Inbox.module.scss"
import { CardTitle, Input } from "../../Components/CustomComponents";

import { FiSend } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";

export default function Inbox({ isMobile = false, setUser }) {

    const handleBackButtonClick = () => {
        setUser('');
    }

    return (
        <div className={styles.Inbox}>
            <div className={styles.titleContainer}>
                <div className={styles.userContainer}>
                    <div className={styles.imageContainer}>
                        {isMobile ?
                            <span
                                className={styles.backButton}
                                onClick={handleBackButtonClick}
                            >
                                <IoIosArrowBack />
                            </span> : ''}

                        <img
                            className={styles.image}
                            src={'/images/profile.png'}
                            alt="User Profile"
                        />
                        <span className={styles.online}></span>

                    </div>
                    <div className={styles.nameContainer}>
                        <CardTitle
                            text={'John Doe'}
                            classes="lc-1"
                        />
                        <p className={styles.online}>Online</p>

                    </div>

                </div>

            </div>
            <div className={styles.chatContainer}>
                {[1, 1, 1, 1, 1, 1, 1].map((item, index) => {

                    const isMe = index % 2;
                    return (
                        <div
                            className={`${styles.chat} ${isMe ? styles.me : ''}`}
                        >
                            <span className={styles.info}>16:00</span>
                            <p
                                className={`${styles.message} ${isMe ? styles.me : ''}`}
                            >
                                Hello, good afternoon!  John Hello
                            </p>
                            <span className={styles.info}>Seen</span>
                        </div>
                    )
                })}

            </div>

            <div className={styles.messageContainer}>
                <Input
                    placeholder="Type a message"
                />
                <button
                    className={`${styles.sendButton} bg`}

                >
                    <FiSend />
                </button>

            </div>

        </div>
    )
}