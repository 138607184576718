import React from "react";

import styles from "../index.module.scss"


import TicketCard from "../../../Components/Cards/TicketCard";
import { Description, EmptyContainer, Loader, SubTitle } from "../../../Components/CustomComponents";

import { TicketAPI } from "../../../Api/Ticket";
import { useQuery, useQueryClient } from "react-query";
import { Toast } from "../../../Hooks/useToast";
import _ from "lodash";

export default function Ticket({ setData = () => { } }) {
    const QUERY_KEY = 'user/ticket'
    const ticketAPI = TicketAPI()
    const queryClient = useQueryClient()
    const { data, isError, isLoading } = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: async () => await ticketAPI.get(),
        select: (data) => data.data,
        onSuccess: (data) => {
            data.forEach(item => queryClient.setQueryData([QUERY_KEY + '/' + item.slug], item));
        },
        onError: (err) => Toast(err.message, 'error', false),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    })

    return (
        <div className={styles.Ticket}>
            <SubTitle
                text={'E-Tickets'}
                classes='mt-4'
            />
            <Description
                text='Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
                classes='fs-8'
            />
            {isLoading ? <Loader /> :
                (isError || _.isEmpty(data)) ? <EmptyContainer component={<SubTitle text="No ticket found" classes="fs-5 fw-lighter" />} /> :
                    <TicketCard data={data} setData={setData} />
            }

        </div>

    )
}