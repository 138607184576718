import React, { useRef, useState } from 'react';
import cssStyles from "../index.module.css";
import { Button, CardTitle, Input } from '../../../Components/CustomComponents';
import { Form } from 'react-bootstrap';
import { getAgeFromDateOfBirth } from '../../../Helper';


const GetRegisterForm = ({ isSubmitted, handleRegistration }) => {
  const ageRef = useRef()
  const [data, setData] = useState({
    fullname: "",
    dateOfBirth: "",
    age: ""
  })



  const handleInputChange = (name, value) => {
    setData(prev => {
      return {
        ...prev,
        [name]: value
      }
    })
  };

  const handleDateOfBirthChange = (value) => {
    setData(prev => {
      return {
        ...prev,
        dateOfBirth: value,
      }
    })
    ageRef.current.value = getAgeFromDateOfBirth(value)
  }

  const handleSubmit = (e) => {
    handleRegistration(data);
  }

  return (
    <div >

      <div style={{ maxWidth: '450px' }}>

        <CardTitle
          text='Full name of your Kid'
          classes='fs-7 mb-1'
        />

        <Input
          classes="mt-2 mb-3"
          placeholder="Enter Full Name"
          name="fullname"
          value={data?.fullname}
          onChange={handleInputChange} />

        <CardTitle
          text='Date of Birth of your Kid'
          classes='fs-7 mb-1'
        />
        <div className='mt-2 mb-3'>

          <Form.Control
            className={`${cssStyles.datePicker}`}
            name="dateOfBirth"
            type="date"
            placeholder='Select Date'
            value={data?.dateOfBirth}
            onChange={(e) => handleDateOfBirthChange(e.target.value)}
            onKeyDown={(e) => e.preventDefault()}
            max={new Date().toISOString().slice(0, 10)}
          />
        </div>

        <CardTitle
          text='Age of your Kid'
          classes='fs-7 mb-1'
        />
        <Input
          classes="mt-2 mb-3"
          placeholder="Enter age"
          name="age"
          disabled={true}
          ref={ageRef}
          readOnly={true}
        />

        <Button
          classes={` mt-2 fs-6`}
          text="Register your Kid"
          isSubmitted={isSubmitted}
          onClick={handleSubmit}
        />

      </div>
      <p></p>
    </div>
  )
}

export default GetRegisterForm
