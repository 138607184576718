import React from "react";
import cssStyles from "./index.module.css";
import { Col, Row } from "react-bootstrap";
import TournamentBanner from "../../Components/Cards/TournamentBanner";
import RegistrationCard from "../../Components/Cards/RegistrationCard";
import GetRegisterForm from "./Components/GetRegisterForm";
import CheckOutSummary from "./Components/CheckOutSummary";
import { ROUTES } from "./Route";

const GetRegisterScreen = ({ setPage }) => {

  const handleRegistrationForm = (name, age, dob) => {

  }

  const handleBackButtonClck = () => {
    setPage(ROUTES.VIEW_TOURNAMENT)
  }

  return (
    <div >
      <Row>
        <Col md={12} lg={8}>
          <TournamentBanner hasBackButton={true}
            handleBackButtonClck={handleBackButtonClck} />
        </Col>
        <Col md={12} lg={4}>
          <div className={cssStyles.topMargins}>
            <RegistrationCard />
          </div>
        </Col>
      </Row>
      <div className="mt-3">
        <Row>
          <Col md={12} lg={8}>
            <GetRegisterForm handleRegistration={handleRegistrationForm} />
          </Col>
          <Col md={12} lg={4}>
            <CheckOutSummary />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GetRegisterScreen;
