import { useSelector } from "react-redux";
import { Axios } from "../Hooks/useAxiosInstance";


export const UserAPI = () => {

    const { route, user } = useSelector(state => state.default)

    const apis = ({ route, api_token }) => ({
        upgrade: async () => await upgradeUser(route, api_token),
    })

    async function upgradeUser(route, api_token) {
        const url = route + "upgrade";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.post(url, {}, option);
    }

    return apis({ route: route, api_token: user.api_token });
}

