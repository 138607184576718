import React, { useState } from 'react';

import styles from './index.module.scss';

import { Button, CardTitle, Description, SubTitle } from '../../Components/CustomComponents';

import { Spinner } from 'react-bootstrap';
import { CiShare2 } from "react-icons/ci";
import { FaBan, FaMapMarkerAlt } from "react-icons/fa";
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { UserAPI } from "../../Api/User";
import ModalContainer from '../../Components/ModalContainer';
import { setUser } from '../../Features/DefaultReducer';
import { Toast } from '../../Hooks/useToast';
import { ROUTES } from './Route';
import AddKid from './components/AddKid';
import Kid from './components/Kid';
import Team from './components/Team';
import TeamView from './components/TeamView';
import Ticket from './components/Ticket';
import TicketDetails from './components/TicketDetails';

export default function ProfileScreen({ setPage }) {
    const useAPI = UserAPI()
    const queryClient = useQueryClient();
    const dispatch = useDispatch()
    const { isViewer: IS_VIEWER, isCoach: IS_COACH, user } = useSelector(state => state.default);
    const [modal, setModal] = useState(false);
    const [showAddKidModal, setShowAddKidModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(true)
    const [details_slug, setDetailSlug] = useState('')

    const coachMutation = useMutation(useAPI.upgrade, {
        onSuccess: (data) => {
            dispatch(setUser(data))
            queryClient.clear()
            setIsSubmitted(false)
        },
        onError: (err) => {
            Toast(err.message, 'error', false)
            setIsSubmitted(false)
        }
    });

    const handleModalClose = () => {
        setModal(false)
    }

    const openAddKidModal = () => {
        setShowAddKidModal(true);
    }

    const closeAddKidModal = () => {
        setShowAddKidModal(false);
    }

    const handleMagicBox = () => {

        setPage(ROUTES.MAGIC_BOX);
    };

    const handleBecameCoach = () => {
        setDetailSlug('')
        coachMutation.mutate()
    }

    return (
        <div className={styles.Profile}>
            {modal ?
                <ModalContainer
                    handleClose={handleModalClose}
                >
                    <div className={styles.modalContainer}>

                        <img
                            src='/images/match_card_4x.png'
                            alt='Tournament Profile'
                            className={styles.image}
                        />
                        <CardTitle
                            text='IEM Rio Major 2024'
                            classes='mt-3 fs-5'
                        />
                        <div className={styles.location}>
                            <span className={styles.icon}><FaMapMarkerAlt /></span>
                            <Description
                                text='At Kimberley International Stadium'
                                classes='fs-9 lc-1'
                            />
                        </div>

                        <hr className='' ></hr>

                        <div className='mt-5 mb-3'>
                            <img
                                src='/images/qr_image.png'
                                alt='QR code '
                                className={styles.qrImage}
                            />
                        </div>
                        <CardTitle
                            text='Gladiators'
                            classes='mt-1 fs-4'
                        />
                        <Description
                            text='5 Members'
                            classes='mt-1 fs-7'
                        />
                        <Description
                            text='Pass ID: WASQL7H'
                            classes='mt-1 fs-7'
                        />

                        <div className='mt-5 d-flex w-100'>
                            <button className={`${styles.shareButton} `}>
                                <CiShare2 className='mx-2' />
                                Share
                            </button>

                            <Button
                                classes={`mx-1 d-flex align-item-center justify-content-center `}
                                text="Continue"
                                onClick={handleModalClose}
                            ></Button>

                        </div>

                    </div>
                </ModalContainer>
                : ''}

            {showAddKidModal ?
                <ModalContainer
                    handleClose={closeAddKidModal}
                >
                    <AddKid handleClose={closeAddKidModal} />
                </ModalContainer>
                :
                ''
            }

            <div className={styles.row}>
                <div
                    className={`${styles.col} ${styles.col1}`}
                    md={5}
                >
                    <SubTitle
                        text={'Profile'}
                    />
                    <Description
                        text='Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
                        classes='fs-8'
                    />
                    <div
                        className={`${styles.userContainer} mt-4 mb-4`}
                    >
                        <img
                            src={user.image_url}
                            alt='User Profile'
                            className={styles.image}
                        />
                        <div className={styles.nameContainer}>
                            <div className={`d-flex flex-row align-items-center`}>
                                <div className='d-flex flex-column'>
                                    <CardTitle
                                        text={user.firstname + ' ' + user.lastname}
                                    />
                                    <Description
                                        text={user.email}
                                        classes={`${styles.email} fs-8`}
                                    />
                                </div>
                                {!user.is_banned ? "" :
                                    <div>
                                        <FaBan style={{ color: "#E95B5B", fontSize: "2rem", marginLeft: "10px" }} />
                                    </div>
                                }
                            </div>

                            {
                                IS_VIEWER ?
                                    !isSubmitted ? <span className={styles.tag} >
                                        <Spinner size="sm" style={{ fontSize: "10px" }} />
                                    </span> :
                                        <span className={styles.tag} onClick={handleBecameCoach}>
                                            Become a coach
                                        </span>
                                    :
                                    <span className={styles.tag}>
                                        Coach
                                    </span>
                            }

                        </div>

                    </div>
                    {IS_VIEWER ? <Ticket setData={setDetailSlug} /> :
                        IS_COACH ?
                            <>
                                {
                                    !user.is_banned ?
                                        <Button
                                            classes={`${styles.panelBtn} ${styles.orangeBg}`}
                                            text="Roster’s Selection"
                                            onClick={handleMagicBox}
                                        ></Button>
                                        : ""
                                    //  <SubTitle text='Banned by the admin' classes='text-center fs-4 mb-5 text-secondary' />
                                }
                                <Team team_slug={details_slug} setData={setDetailSlug} />
                            </>
                            : ""
                    }

                    <Kid isAddKid={(IS_VIEWER || IS_COACH)} handleAddKid={(IS_VIEWER || IS_COACH) ? openAddKidModal : null} />
                </div>


                <div
                    className={`${styles.col} ${styles.teamInfoSection}`}
                    md={7}
                >
                    {
                        !details_slug ? "" :
                            IS_VIEWER ?
                                <TicketDetails ticket_slug={details_slug} />
                                :
                                <TeamView key={details_slug} team_slug={details_slug} />
                    }


                </div>

            </div >

        </div >
    )
}