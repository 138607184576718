import CryptoJS from 'crypto-js';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CONTAINER_KEYS } from "../constants";
import { logout, setLoggedIn, setUser } from "../Features/DefaultReducer";
import { localStorageToken, removeSplashScreen } from "../Helper";
import { useGetProfileMutation } from '../services/UserService';


export function useLocalStorage() {
    const dispatch = useDispatch();
    const [getProfile] = useGetProfileMutation();


    async function getUserProfile(api_token) {
        try {
            const response = await getProfile(api_token).unwrap();
            dispatch(setUser({ data: response?.data }));
        }
        catch (err) {
            dispatch(logout());
        }
        finally {
            removeSplashScreen()
        }

    }

    useEffect(() => {
        const token = localStorage.getItem(localStorageToken());
        if (token) {
            const decrypted = CryptoJS.AES.decrypt(token, process.env.REACT_APP_SECRET_KEY);
            const data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
            getUserProfile(data.api_token);
        }
        else {
            dispatch(setLoggedIn(false));
            removeSplashScreen()
        }
    }, [])


    return;


}