import React, { useEffect, useMemo, useRef, useState } from "react";

import styles from "./styles/Tournament.module.scss"

import TournamentBanner from "../../Components/Cards/TournamentBanner";
import { CardDescription, CardMatchTitle, SubTitle, CardTitle, LiveBadge, Score, Input, Loader, EmptyContainer, Description } from "../../Components/CustomComponents";
import BorderedCard from "../../Components/Cards/BorderedCard";

import { Col, Row } from "react-bootstrap";
import { FiSend } from "react-icons/fi";
import { ROUTES } from "./constants";
import Tabs from "../../Components/Tabs/Tabs";
import { MatchAPI } from "../../Api/Match";
import { TournamentAPI } from "../../Api/Tournament";
import { useQuery, useQueryClient } from "react-query";
import { Toast } from "../../Hooks/useToast";
import _ from "lodash";
import { IoChevronBackOutline } from "react-icons/io5";
import { GrFormNext } from "react-icons/gr";
import { useSocket } from "../../Context/SocketContext";
import { useInView } from "react-intersection-observer";
import { v4 as uuid } from 'uuid';
import { useSelector } from "react-redux";

export default function Tournament({ data = {}, setPage }) {
    const matchAPI = MatchAPI()
    const tournamentAPI = TournamentAPI()
    const socket = useSocket()
    const queryClient = useQueryClient();
    const { ref, inView } = useInView({
        threshold: 0,
    });
    const { user } = useSelector(state => state.default)

    const QUERY_KEY = 'tournament/' + data.slug + '/live';
    const [selectedAgeGroup, setSelectedAgeGroup] = useState(_.isEmpty(data?.age_groups) ? '' : data?.age_groups[0].value)

    const MATCH_QUERY_KEY = 'tournament/age-group/' + selectedAgeGroup + '/match';
    const LIVE_MATCH_QUERY_KEY = 'tournament/' + data.slug + '/live-match';
    const { data: matchListData, isError: isMatchListError, isLoading: isMatchListLoading } = useQuery({
        queryKey: MATCH_QUERY_KEY,
        enabled: !!selectedAgeGroup,
        queryFn: async () => await matchAPI.getTournamentMatches(selectedAgeGroup),
        select: (res) => res.data,
        onError: (err) => Toast(err.message, 'error', false),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    })

    const { data: liveMatchData, isError: isLiveMatchError, isLoading: isLiveMatchLoading } = useQuery({
        queryKey: LIVE_MATCH_QUERY_KEY,
        queryFn: async () => await tournamentAPI.getTournamentLiveMatches(data.slug),
        select: (res) => res.data,
        onSuccess: (data) => {
            if (!_.isEmpty(data) && _.isEmpty(liveMatchRecord)) {
                setLiveMatchRecord(data[0])
                setMatchRoom(data[0].slug)
            }
            else if (_.isEmpty(data) && !_.isEmpty(liveMatchRecord)) {
                setLiveMatchRecord({})
                setMatchRoom('')
            }
        },
        onError: (err) => Toast(err.message, 'error', false),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    })

    const [liveMatchRecord, setLiveMatchRecord] = useState(_.isEmpty(liveMatchData) ? {} : liveMatchData[0])
    const [matchRoom, setMatchRoom] = useState(_.isEmpty(liveMatchData) ? "" : liveMatchData[0].slug)
    const messageRef = useRef('')

    const INITIAL_CHAT_HISTORY = useMemo(() => {
        return {
            page: 0,
            limit: 20,
            offset: 0,
            total: 0,
            isLoading: true,
            isError: false,
            data: [],
        }
    }, [])
    const [matchRoomChat, setMatchRoomChat] = useState(INITIAL_CHAT_HISTORY)

    useEffect(() => {
        socket.on('MATCH_END', (data) => {
            const prev = queryClient.getQueryData(LIVE_MATCH_QUERY_KEY)
            const updated_data = (prev.data ?? []).filter(item => item.slug !== data.match_slug)
            if (_.isEmpty(updated_data)) {
                setLiveMatchRecord({})
                setMatchRoom('')
            }
            else {
                setLiveMatchRecord(updated_data[0])
            }
            queryClient.invalidateQueries({ queryKey: MATCH_QUERY_KEY, refetchActive: true });
            queryClient.setQueryData(LIVE_MATCH_QUERY_KEY, { ...prev, data: updated_data })
        })

        socket.on('MATCH_START', (data) => {
            const prev = queryClient.getQueryData(LIVE_MATCH_QUERY_KEY)
            const updated_data = (prev?.data ?? []).map(item => (item.slug !== data.match_slug) ? item : { ...item, is_started: true, score: data.score })
            if (liveMatchRecord.slug === data.match_slug) {
                setLiveMatchRecord(prev => {
                    return {
                        ...prev,
                        is_started: true,
                        score: data.score
                    }
                })
            }
            queryClient.setQueryData(LIVE_MATCH_QUERY_KEY, { ...prev, data: updated_data })
        })

        return () => {
            socket.dispose('MATCH_END')
            socket.dispose('MATCH_START')
        }
    }, [liveMatchRecord])

    useEffect(() => {
        if (matchRoom) {
            socket.emit('_joinRoom', matchRoom)

            socket.emit('_loadMatchChatHistory', {
                page: matchRoomChat.page,
                offset: matchRoomChat.offset,
                limit: matchRoomChat.limit,
                match_slug: matchRoom
            })
        }

        socket.on('receivedMatchChatMessage_', (data) => {
            if (data.payload.match_slug !== matchRoom) return;
            setMatchRoomChat(prev => {
                return {
                    ...prev,
                    isLoading: false,
                    data: [data.data, ...(prev.data.filter(item => item.temp_id !== data.payload.temp_id))],
                    total: prev.total + 1,
                    offset: prev.offset + 1,
                }
            })
        })

        socket.on('loadMatchChatHistory_', (data) => {
            if (data.payload.match_slug !== matchRoom) return;

            setMatchRoomChat(prev => {
                return {
                    ...prev,
                    isLoading: false,
                    data: [...prev.data, ...data.data],
                    page: prev.page + 1,
                    total: data.links.total_records,
                }
            })
        })

        return () => {
            if (matchRoom) {
                socket.emit('_leaveRoom', matchRoom)
            }
            socket.dispose('receivedMatchChatMessage_');
            socket.dispose('loadMatchChatHistory_');
        }
    }, [matchRoom])

    useEffect(() => {
        if (inView) {
            socket.emit('_loadMatchChatHistory', {
                page: matchRoomChat.page + 1,
                offset: matchRoomChat.offset,
                limit: matchRoomChat.limit,
                match_slug: matchRoom
            })
        }
    }, [inView])




    const handleBackButtonClck = () => {
        setPage(data?.previous_route || ROUTES.DETAILS)
    }
    const handlePrev = () => {
        const index = liveMatchData.findIndex(item => item.slug === liveMatchRecord.slug)
        if (index === 0) {
            const data = liveMatchData[liveMatchData.length - 1];
            setLiveMatchRecord(data);
            setMatchRoom(data.slug)
        }
        else {
            const data = liveMatchData[index - 1]
            setLiveMatchRecord(data)
            setMatchRoom(data.slug)
        }
        setMatchRoomChat(INITIAL_CHAT_HISTORY)

    }
    const handleNext = () => {
        const index = liveMatchData.findIndex(item => item.slug === liveMatchRecord.slug)
        if (index === (liveMatchData.length - 1)) {
            const data = liveMatchData[0];
            setLiveMatchRecord(data)
            setMatchRoom(data.slug)
        }
        else {
            const data = liveMatchData[index + 1];
            setLiveMatchRecord(data)
            setMatchRoom(data.slug)
        }
        setMatchRoomChat(INITIAL_CHAT_HISTORY)
    }


    const handleSendMessage = () => {
        const message = messageRef.current.value;
        if (!message) return;

        const payload = {
            match_slug: matchRoom,
            message_type: 'TEXT',
            message,
            user_image: user.image_url,
            user_name: user.name,
            temp_id: uuid()
        }
        messageRef.current.value = '';
        setMatchRoomChat(prev => {
            return {
                ...prev,
                data: [payload, ...prev.data]
            }
        })
        socket.emit('_sendMatchMessage', payload)
    }
    return (
        <div className={styles.Tournament}>
            <Row className={styles.row}>
                <Col
                    className={styles.item1}
                >
                    <TournamentBanner
                        live={true}
                        hasBackButton={true}
                        handleBackButtonClck={handleBackButtonClck}
                        QUERY_KEY={QUERY_KEY}
                    />
                </Col>
                <Col
                    className={styles.item2}
                >
                    {isLiveMatchLoading ?
                        <div className={styles.loaderContainer}>
                            <Loader />
                        </div> :
                        (isLiveMatchError || _.isEmpty(liveMatchRecord)) ?
                            <BorderedCard classes={styles.overflowUnset}>
                                <EmptyContainer text="No live match yet" />
                            </BorderedCard> :
                            <div className={styles.infoContainer}>
                                <div
                                    className={styles.subItem1}
                                >
                                    <BorderedCard classes={styles.overflowUnset}>
                                        <div className={styles.matchInfo}>
                                            {(liveMatchData.length < 2) ? "" :
                                                <>
                                                    <span
                                                        className={`${styles.control} ${styles.prev}`}
                                                        onClick={handlePrev}
                                                    >
                                                        <IoChevronBackOutline />
                                                    </span>
                                                    <span
                                                        className={`${styles.control} ${styles.next}`}
                                                        onClick={handleNext}
                                                    >
                                                        <GrFormNext /></span>
                                                </>}
                                            <div className={styles.teamContainer}>
                                                <div className={styles.team}>
                                                    <img
                                                        src={liveMatchRecord.teamA_image_url}
                                                        alt='Team 1'
                                                        className={`${styles.logo}`}
                                                    />

                                                    <CardTitle
                                                        text={liveMatchRecord.teamA_name}
                                                        classes="fs-8 lc-1"
                                                    />
                                                </div>
                                                <div className={styles.seperator}>
                                                    <LiveBadge classes="mb-1" />
                                                    <CardTitle
                                                        text={`Match ${liveMatchRecord.match_no}`}
                                                        classes='fs-6'
                                                    />
                                                    <CardDescription
                                                        text={liveMatchRecord.start_date}
                                                        classes="fs-8"
                                                    />
                                                    <CardDescription
                                                        text={liveMatchRecord.age_group}
                                                        classes="fs-8"
                                                    />
                                                    <span className={styles.vs}>VS</span>


                                                </div>
                                                <div className={styles.team}>
                                                    <img
                                                        src={liveMatchRecord.teamB_image_url}
                                                        alt='Team 2'
                                                        className={`${styles.logo}`}
                                                    />
                                                    <CardTitle
                                                        text={liveMatchRecord.teamB_name}
                                                        classes="fs-8 lc-1"
                                                    />

                                                </div>
                                            </div>
                                            {!liveMatchRecord.is_started ?
                                                <Description text="Match not started yet" classes="mt-3 text-center" /> :
                                                <div className={styles.statsContainer}>
                                                    <div className={styles.team}>
                                                        <CardTitle
                                                            text="Away"
                                                            classes="mb-1"
                                                        />
                                                        <Score
                                                            text={liveMatchRecord?.score.away}
                                                            classes="p-2 fw-normal fs-5"
                                                        />
                                                    </div>
                                                    <div className={styles.stats}>
                                                        <div className={styles.stat}>
                                                            <Score
                                                                text={liveMatchRecord?.score.inning}
                                                                classes="p-2 fs-8"
                                                            />
                                                            <CardDescription text='Innings' />

                                                        </div>
                                                        <div className={styles.ballContainer}>
                                                            <div className={styles.stat}>
                                                                <Score
                                                                    text={liveMatchRecord?.score.ball}
                                                                    classes="p-2 fs-8"
                                                                />
                                                                <CardDescription text='Ball' />
                                                            </div>
                                                            <div className={styles.stat}>
                                                                <Score
                                                                    text={liveMatchRecord?.score.strike}
                                                                    classes="p-2 fs-8"
                                                                />
                                                                <CardDescription text='Strike' />
                                                            </div>
                                                            <div className={styles.stat}>
                                                                <Score
                                                                    text={liveMatchRecord?.score.out}
                                                                    classes="p-2 fs-8"
                                                                />
                                                                <CardDescription text='Out' />
                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div className={styles.team}>
                                                        <CardTitle
                                                            text="Home"
                                                            classes="mb-1"
                                                        />
                                                        <Score
                                                            text={liveMatchRecord?.score.home}
                                                            classes="p-2 fw-normal fs-5"
                                                        />
                                                    </div>


                                                </div>
                                            }

                                        </div>
                                    </BorderedCard>
                                </div>
                                <div
                                    className={styles.subItem2}
                                >
                                    <div className={styles.liveChat}>
                                        <CardTitle
                                            text="Live Chat"
                                            classes="fs-4 mb-2 fw-bold"
                                        />

                                        <div className={styles.chatContainer}>
                                            {matchRoomChat.isLoading ? <Loader containerStyle="py-3" /> : ""}
                                            {(matchRoomChat.data ?? []).map((item, index) => {
                                                return (
                                                    <div
                                                        className={`${styles.chat} my-3`}
                                                        key={index}
                                                        ref={
                                                            (matchRoomChat.data.length >= matchRoomChat.total) ? null :
                                                                (index === (matchRoomChat.data.length - 1)) ? ref : null}
                                                    >
                                                        <div className={styles.userImage}>
                                                            <img
                                                                src={item.user_image}
                                                                alt={`User Profile ${index}`}
                                                                className={styles.image}
                                                            />
                                                        </div>
                                                        <div className={styles.userInfo}>
                                                            <CardTitle
                                                                text={item.user_name}
                                                                classes="fs-7 mb-1"
                                                            />
                                                            <CardTitle
                                                                text={item.message}
                                                                classes="fs-8 fw-lighter"
                                                            />
                                                        </div>
                                                    </div>
                                                )

                                            }
                                            )}

                                        </div>
                                        <div className={styles.messageContainer}>
                                            <Input
                                                classes="px-3 py-1 fs-7"
                                                size="sm"
                                                placeholder="Type a message"
                                                ref={messageRef}
                                            />
                                            <span className={styles.sendButton} onClick={handleSendMessage} ><FiSend /></span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </Col>
                <Col
                    className={styles.item3}
                >
                    <SubTitle
                        text="Tournament Matches"
                        classes="mt-3 mb-1"
                    />
                    <Tabs
                        tabs={data?.age_groups}
                        current={selectedAgeGroup} onChange={setSelectedAgeGroup}
                        itemStyle={{
                            background: '#222232',
                            margin: '5px',
                            padding: '8px 15px',
                        }}
                        containerStyles={{ width: 'fit-content', marginBottom: "20px" }}
                    />
                    <BorderedCard>
                        {isMatchListLoading ? <Loader /> :
                            (isMatchListError || _.isEmpty(matchListData)) ? <EmptyContainer text="Matches not found" /> :
                                <Row className={styles.matchesContainer}>
                                    {(matchListData ?? []).map((item, index) => <Col xl={6} lg={12} md={6} xs={12} className={'my-2'} key={index}>
                                        <div
                                            className={styles.match}
                                        >
                                            <CardMatchTitle
                                                text={`MATCH ${item.match_no}`}
                                                classes="fw-bolder"
                                            />
                                            <div className={`${styles.teamContainer} my-2`}>
                                                <div
                                                    className={`${styles.team} ${styles.teamInfo1}`}
                                                >
                                                    {!item.teamA_slotted ? <span className={`${styles.name} ${styles.team1}`}>N/A</span> :
                                                        <>
                                                            <img
                                                                className={styles.teamLogo}
                                                                src={item.teamA_image_url}
                                                                alt={`Team A Logo ${index}`}
                                                            />
                                                            <span className={`${styles.name} ${styles.team1} lc-2`}>{item.teamA_name}</span>
                                                        </>
                                                    }
                                                </div>
                                                <span className={styles.separator}>VS</span>
                                                <div
                                                    className={`${styles.team} ${styles.teamInfo2}`}
                                                >
                                                    {!item.teamB_slotted ? <span className={`${styles.name} ${styles.team2}`}>N/A</span> :
                                                        <>
                                                            <span className={`${styles.name} ${styles.team2} lc-2`}>{item.teamB_name}</span>
                                                            <img
                                                                className={styles.teamLogo}
                                                                src={item.teamB_image_url}
                                                                alt={`Team 2 Logo ${index}`}
                                                            />
                                                        </>
                                                    }
                                                </div>


                                            </div>
                                            <CardDescription
                                                text={item.start_date} />

                                        </div>
                                    </Col>
                                    )}
                                </Row>
                        }
                    </BorderedCard>
                </Col>
            </Row>

        </div >
    )
}