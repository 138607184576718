import React from 'react';

import styles from './styles/MatchCard.module.scss'
import { CardDescription, CardMatchTitle, CardTitle } from '../CustomComponents';
import BorderedCard from './BorderedCard';

export default function MatchCard({ data = {} }) {
    return (
        <BorderedCard>
            <div className={styles.MatchCard}>
                <img
                    src={data.tournament_image}
                    alt="Match Team"
                    className={styles.matchLogo}
                />
                <CardTitle
                    text={data.tournament_name}
                    classes='mt-1'
                />
                <CardDescription
                    text={data.tournament_location}
                />
                <CardMatchTitle
                    text={`MATCH ${data.match_no}`}
                />
                <div className={`${styles.opponentContainer} mt-1`}>
                    <div
                        className={styles.teamLogoContainer}
                    >
                        <img
                            className={styles.teamLogo}
                            src={data.teamA_image_url}
                            alt="Team 1 Logo"
                        />
                        {!data.is_started ? "" : <span className={`${styles.score} mt-1`}>{data.score.away}</span>}
                    </div>
                    <span className={styles.separator}>VS</span>
                    <div
                        className={styles.teamLogoContainer}
                    >
                        <img
                            className={styles.teamLogo}
                            src={data.teamB_image_url}
                            alt="Team 2 Logo"
                        />
                        {!data.is_started ? "" : <span className={`${styles.score} mt-1`}>{data.score.home}</span>}
                    </div>

                </div>
                {data.is_started ? "" :
                    <CardDescription text='Match not started yet' classes='mt-2' />
                }

            </div>
        </BorderedCard>
    )
}