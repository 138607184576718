import React from 'react';
import { Button } from '../CustomComponents';
import cssStyles from './styles/index.module.css';
import { Col } from 'react-bootstrap';


const TeamAgeCard = ({ data = {}, sNo = 0, handleShowViewTeam = () => { } }) => {
  return (
    // <div style={{ display: 'flex', background: '#222232', borderRadius: '10px', }}>

    <div className='p-2 rounded-4' style={{ background: '#222232', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
      <div
        style={{ width: "100%", display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      // xs={{ order: 2, span: 12 }}
      // sm={{ order: 1, span: 12 }}
      // md={{ order: 1, span: 12 }}

      // lg={{ order: 1, span: 8 }}
      // xl={{ order: 1, span: 8 }}
      // xxl={{ order: 1, span: 8 }}
      >

        <span className='p-2' > {sNo + 1} </span>

        <img
          style={{ width: '45px', height: '45px', margin: '0 10px', borderRadius: "100%" }}
          src={data.image_url}
          alt={`Team ${sNo} Logo`}
        />

        <p className=' fw-bold lc-1 m-0'>{data.name}</p>

        <Button
          classes={`${cssStyles.viewTeamButton} `}
          text="View Team"
          // onClick={handleViewTeam}
          onClick={handleShowViewTeam}
        ></Button>

      </div>
    </div>

  )
}

export default TeamAgeCard


