import moment from "moment";
import React from "react";
import { Badge } from "react-bootstrap";

const TimeBadge = ({ time, color }) => {
  const badgeStyle = {
    color: "#0AA7FF",
    backgroundColor: color || "#fff",
    fontSize: "1rem",
    fontWeight: "bold",
    borderRadius: "16px",
    width: 'fit-content',
    marginLeft: 'auto',
    padding: "10px"
  };

  return (
    <Badge bg="" style={badgeStyle}>
      <p style={{ fontSize: ".8rem", margin: 0 }}>{moment(time).format("MMM DD, YYYY")}</p>
      <p style={{ fontSize: ".7rem", margin: "4px 0 0 0" }}>{moment(time).format("hh:mm A")}</p>
    </Badge>
  );
};

export default TimeBadge;
