import React from "react";

import styles from "./styles/TicketCard.module.scss"
import { CardTitle, Description } from "../CustomComponents";
import { FaMapMarkerAlt } from "react-icons/fa";

export default function TicketCard({ data = [], setData = () => { } }) {
    return data?.map((item, index) => {
        return <div
            key={index}
            className={`${styles.ticketContainer} mt-3 mb-2 cursor-pointer`}
            onClick={() => setData(item.slug)}
        >
            <>
                <div className={styles.teamContainer} key={index}>

                    <div className={styles.tournament}>
                        <img
                            src={item.thumbnail_url}
                            alt='Tournament Profile'
                            className={styles.tournamentLogo}
                        />
                        <div className={styles.nameContainer}>
                            <CardTitle
                                text={item.name}
                                classes='lc-1'
                            />
                            <div className={styles.location}>
                                <span className={styles.icon}><FaMapMarkerAlt /></span>
                                <Description
                                    text={item.location}
                                    classes='fs-9 lc-1'
                                />

                            </div>

                        </div>

                    </div>

                </div>
                <div className={styles.qrContainer}>
                    <img
                        src='/images/qr.png'
                        alt='QR Code'
                        className={styles.qrImage}
                    // onClick={handleModalOpen}
                    />
                    <Description
                        text='Team QR'
                        classes='fs-8 text-center mt-2 text-nowrap'
                    />

                </div>
            </>
        </div>
    })
}