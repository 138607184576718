import React, { useRef, useState } from "react";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { Button, Checbox, Description, Input, Title } from "../../Components/CustomComponents";
import { Col, Form, Row } from "react-bootstrap";
import { ACCOUNT_ENUM } from "./Route";
import { useDispatch } from "react-redux";
import { setUser, updateLogin } from "../../Features/DefaultReducer";
import SelectFieldComponent from "../Tournaments/Components/SelectFieldComponent";
import filterStyles from "../../Components/Modals/styles/filter.module.css";
import { Toast } from "../../Hooks/useToast"
import { useCreateUserMutation } from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import { getAgeFromDateOfBirth } from "../../Helper";

export default function Register({ setPage }) {
    const [createUser] = useCreateUserMutation()
    const navigate = useNavigate()

    const playerAgeRef = useRef()
    const kidAgeRef = useRef()
    const [data, setData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirm_password: "",
    })
    const [playerData, setPlayerData] = useState({
        dateOfBirth: '',
    })

    const [kidData, setKidData] = useState({
        fullname: "",
        dateOfBirth: ''
    })

    const [isAgreed, setIsAgreed] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedAnswer, setSelectedAnswer] = useState('');

    const roleList = [
        { label: 'Viewer', value: 'VIEWER' },
        { label: 'Player', value: 'PLAYER' }
    ];
    const answerList = [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
    ];

    const handleInputChange = (name, value) => {
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handlePlayerInputChange = (name, value) => {
        setPlayerData(prev => {
            return {
                ...prev,
                [name]: value,
            }
        })
    }

    const handleKidInputChange = (name, value) => {
        setKidData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handlePlayerDateOfBirthChange = (value) => {
        setPlayerData(prev => {
            return {
                ...prev,
                dateOfBirth: value,
            }
        })
        playerAgeRef.current.value = getAgeFromDateOfBirth(value)
    }


    const handleKidDateOfBirthChange = (value) => {
        setKidData(prev => {
            return {
                ...prev,
                dateOfBirth: value,
            }
        })
        kidAgeRef.current.value = getAgeFromDateOfBirth(value)
    }

    const handleRoleChange = (value) => setSelectedRole(value);
    const handleAnswerChange = (value) => setSelectedAnswer(value);
    const handleLoginClick = () => setPage(ACCOUNT_ENUM.LOGIN);
    const handleCheckBoxClick = () => setIsAgreed(!isAgreed);

    const handleSubmit = async () => {
        if (!data?.firstname) return Toast('First name is required', 'error', false)
        if (!data?.lastname) return Toast('Last is required', 'error', false)
        if (!data?.email) return Toast('Email is required', 'error', false)
        if (!data?.password) return Toast('Password is required', 'error', false)
        if (!data?.confirm_password) return Toast('Confirm password is required', 'error', false)
        if (data?.confirm_password !== data.password) return Toast("Confirm password doesn't match with password", 'error', false)
        if (!selectedRole) return Toast('Select user role first', 'error', false)

        if (selectedRole === 'VIEWER') {
            if (!selectedAnswer) return Toast('Enter kids option', 'error', false)
            if (selectedAnswer === 'yes') {
                if (!kidData?.fullname) return Toast('Kid name is required', 'error', false)
                if (!kidData?.dateOfBirth) return Toast('Kid date of birth is required', 'error', false)
                // if (!kidData?.age) return Toast('Kid age is required', 'error', false)
            }
        }
        else {
            if (!playerData?.dateOfBirth) return Toast('Date of birth is required', 'error', false)
            // if (!playerData?.age) return Toast('Age is required', 'error', false)
        }
        if (!isAgreed) return Toast('Agree terms and conditions', 'info', false)
        try {
            setIsSubmitted(true)
            const payload = {
                ...data,
                ...playerData,
                user_type: selectedRole,
                have_kids: selectedAnswer,
                kid_data: kidData,
            }
            const response = await createUser(payload).unwrap();
            setPage(ACCOUNT_ENUM.OTP, { email: data.email, previousRoute: ACCOUNT_ENUM.LOGIN })
        }
        catch (err) {
            Toast(err.message, 'error', false)
            setIsSubmitted(false)
        }
    }

    const renderKidFields = () => (
        <>
            <Input
                name="fullname"
                value={kidData.fullname}
                onChange={handleKidInputChange}
                placeholder="Full name of your kid"
            />
            <Form.Control
                style={{ margin: '4px 0px 8px 0px' }}
                className={`${filterStyles.datePicker}`}
                name="dateOfBirth"
                type="date"
                placeholder='Date of Birth'
                value={kidData.dateOfBirth}
                onChange={(e) => handleKidDateOfBirthChange(e.target.value)}
                onKeyDown={(e) => e.preventDefault()}
                max={new Date().toISOString().slice(0, 10)}

            />
            <Input
                name="age"
                value={kidData.age}
                onChange={handleKidInputChange}
                placeholder="Age of your kid"
                disabled={true}
                ref={kidAgeRef}
            />
        </>
    );

    const renderPlayerFields = () => (
        <>
            <Form.Control
                style={{ margin: '4px 0px 8px 0px' }}
                className={`${filterStyles.datePicker}`}
                name="dateOfBirth"
                type="date"
                placeholder='Date of Birth'
                value={playerData.dateOfBirth}
                onChange={(e) => handlePlayerDateOfBirthChange(e.target.value)}
                onKeyDown={(e) => e.preventDefault()}
                max={new Date().toISOString().slice(0, 10)}
            />
            <Input
                name="age"
                value={playerData.age}
                onChange={handlePlayerInputChange}
                placeholder="Enter your age"
                disabled={true}
                ref={playerAgeRef}
            />
        </>
    );

    return (
        <AccountLayout registeredPage={false}>
            <Description text="Hello there," />
            <Title text="Register Yourself" />
            <Row className="w-100">
                <Col>
                    <Row>
                        <Col
                            md={6}
                        >
                            <Input
                                name="firstname"
                                value={data.firstname}
                                onChange={handleInputChange}
                                placeholder="First name"
                            />
                        </Col>
                        <Col
                            md={6}
                        >
                            <Input
                                name="lastname"
                                value={data.lastname}
                                onChange={handleInputChange}
                                placeholder="Last name"
                            />
                        </Col>
                    </Row>
                    <Input
                        name="email"
                        value={data.email}
                        onChange={handleInputChange}
                        placeholder="Email Address"
                    />
                    <Input
                        type="password"
                        name="password"
                        value={data.password}
                        onChange={handleInputChange}
                        classes="mt-2 mb-2"
                        placeholder="Password"
                    />
                    <Input
                        classes="mt-2 mb-2"
                        type="password"
                        name="confirm_password"
                        value={data.confirm_password}
                        onChange={handleInputChange}
                        placeholder="Confirm Password"
                    />


                    <SelectFieldComponent
                        style={{ margin: '4px 0px 6px 0px' }}
                        firstOption={selectedRole ? selectedRole : 'Are you a Viewer or Player?'}
                        optionsList={roleList}
                        onSelectChange={handleRoleChange}
                    />

                    {selectedRole === 'VIEWER' &&
                        <SelectFieldComponent
                            style={{ margin: '4px 0px 6px 0px' }}
                            firstOption={selectedAnswer ? selectedAnswer : 'Do you have a kid?'}
                            optionsList={answerList}
                            onSelectChange={handleAnswerChange}
                        />
                    }

                    {selectedRole === 'PLAYER' && renderPlayerFields()}


                    {selectedAnswer === 'yes' && selectedRole === 'VIEWER' && renderKidFields()}


                    <Checbox
                        label='Agree to Prime Time Tournaments Terms and Conditions & Privacy Policy'
                        classes="mt-2 mb-2"
                        checked={isAgreed}
                        onChange={handleCheckBoxClick}
                    />
                    <Button
                        isSubmitted={isSubmitted}
                        onClick={handleSubmit}
                        text='Register'
                        classes="mt-4 mb-2"
                    />
                    <div
                        className='d-flex justify-content-center flex-direction-row'
                    >
                        <Description
                            text='Already a memeber?'
                            classes="fs-7"
                        />
                        <Description
                            text='Login'
                            classes="link fs-7 ps-1"
                            onClick={handleLoginClick}
                        />

                    </div>
                </Col>

            </Row>

        </AccountLayout >
    )
}