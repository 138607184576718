import React, { useContext, useEffect } from "react";
import socket from "../Config/Socket";
import { useDispatch, useSelector } from "react-redux";
import { logout, socketConntected } from "../Features/DefaultReducer";
// import { updateKey } from "../features/DefaultReducer";


const SocketContext = React.createContext();

export function useSocket() {
    return useContext(SocketContext);
}


export function SocketProvider({ children }) {
    const { isLoggedIn, user } = useSelector(state => state.default)
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoggedIn) {
            socket.connectToSocket(
                user?.api_token,
                () => {
                    dispatch(logout())
                },
                () => {
                    dispatch(socketConntected())
                });
        }
        else {
            socket.disconnect()
        }
        return () => socket.disconnect();
    }, [isLoggedIn])

    return <SocketContext.Provider value={socket}>
        {children}
    </SocketContext.Provider>
}