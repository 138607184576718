import { useSelector } from "react-redux";
import { Axios } from "../Hooks/useAxiosInstance";
import ChangePassword from "../Pages/Setting/ChangePassword";

export const ProfileAPI = () => {
    const { route, user } = useSelector(state => state.default)

    const apis = ({ route, api_token }) => ({
        post: async (data) => await postRecord(data),
        update: async (data) => await updateRecord(data, api_token),
        changePassword: async (data) => await changePassword(data, api_token),
    })

    async function postRecord(data) {
        const url = "user/login";
        const option = {
            headers: {
                'token': '48aaf1a4-9c95-478b-b93f-739ddfa1b38e',
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    async function updateRecord(data, api_token) {
        const url = "user";
        const option = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': api_token
            }
        }

        return await Axios.patch(url, data, option);
    }

    async function changePassword(data, api_token) {
        const url = "user/change-password";
        const option = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': api_token
            }
        }

        return await Axios.post(url, data, option);
    }

    return apis({ route: route, api_token: user.api_token });

}
