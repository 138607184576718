import React from "react";

import styles from "./styles/TournamentCard.module.scss"

import { CardTitle } from "../CustomComponents";

import { FaArrowRightLong } from "react-icons/fa6";
import BorderedCard from "./BorderedCard";

export default function TournamentCard({ data, onClick = () => { } }) {
    return (
        <BorderedCard>
            <div className={styles.TournamentCard} onClick={onClick}>
                <div className={styles.imageContainer}>
                    <img
                        src={data.media_url}
                        alt='Tournament Banner'
                        className={styles.banner}
                    />
                </div>
                <div className={styles.content}>
                    <CardTitle
                        text={data.name}
                        classes="lc-1"
                    />
                    {/* <Description
                        text={'12 Team Matches'}
                        classes="link fs-8 mt-1"
                    /> */}
                    <span className={styles.rightArrow}><FaArrowRightLong /></span>

                </div>

            </div>
        </BorderedCard>
    )
}