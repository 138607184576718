import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseRoute } from "../../Helper";
import { WEB_ROUTES } from "../../constants";

function RoleProtectedRoute({ children }) {

    const { isPlayer: IS_PLAYER } = useSelector(state => state.default);

    return (
        IS_PLAYER ? <Navigate to={baseRoute(WEB_ROUTES.DASHBOARD)} replace={true} /> : children
    );
}

export default RoleProtectedRoute;