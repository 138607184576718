export const BASE_URL = process.env.REACT_APP_API_DOMAIN

export const WEB_ROUTES = {
    ACCOUNT: 'account',
    DASHBOARD: 'dashboard',
    TOURNAMENT: 'tournaments',
    MESSAGE: 'messages',
    PAYMENT_METHOD: 'payment-methods',
    SETTING: 'settings',
    PROFILE: 'profile'
}

export const BREAKPOINTS = {
    MOBILE: 991
}
export const ROLES = {
    VIEWER: 'VIEWER',
    COACH: 'COACH',
    PLAYER: 'PLAYER',
}

export const API_ROUTES = {
    LOGIN: 'api/user/login',
    CREATE_USER: 'api/user',
    UPDATE_USER: 'api/user',
    VERIFY_OTP_REGISTER: 'api/user/verify-otp/register',
    GET_PROFILE: 'api/user',

}

export const CONTAINER_KEYS = {
    SPLASH_SCREEN: 'splash',
    SPLASH_SCREEN_IMAGE: 'splash-image'
}

export const IMAGE_TYPES = '*.png,*jpeg,*.jpg,image/*'

export const FIELDS_MAPPING = {
    "current_password": "Current password",
    "new_password": "New password",
    "confirm_password": "Confirm password",
}


export const SPORTS_TYPE = {
    BASEBALL: "Baseball",
    SOFTBALL: "Softball"
}

export const PASSWORD_PATTERN = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/