import { useSelector } from "react-redux";
import { Axios } from "../Hooks/useAxiosInstance";


export const AgeGroupAPI = () => {

    const { user } = useSelector(state => state.default)

    const apis = ({ api_token }) => ({
        get: async () => await getRecord(api_token),
    })

    async function getRecord(api_token) {
        const url = "user/age-group";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }
    return apis({ api_token: user.api_token });
}

