import React, { useEffect } from 'react';

import styles from "./styles/LiveMatches.module.scss"

import { BackButton, EmptyContainer, Loader, SubTitle } from '../../Components/CustomComponents';
import { Row, Col } from 'react-bootstrap';
import MatchCard from '../../Components/Cards/MatchCard';
import { ROUTES } from './constants';
import { useQueries, useQuery, useQueryClient } from 'react-query';
import { Toast } from '../../Hooks/useToast';
import { MatchAPI } from '../../Api/Match';
import _ from 'lodash';
import { useSocket } from '../../Context/SocketContext';


export default function LiveMatches({ setPage }) {
    const matchAPI = MatchAPI()
    const socket = useSocket()
    const queryClient = useQueryClient();

    const [topMatchQuery, allMatchQuery] = useQueries([
        {
            queryKey: ['match/live/top'],
            queryFn: async () => await matchAPI.getTopLive(),
            select: (res) => res.data,
            onError: (err) => Toast(err.message, 'error', false),
            refetchOnMount: true,
            refetchOnReconnect: true,
            refetchOnWindowFocus: true,
        },
        {
            queryKey: ['match/live'],
            queryFn: async () => await matchAPI.getLive(),
            select: (res) => res.data,
            onError: (err) => Toast(err.message, 'error', false),
            refetchOnMount: true,
            refetchOnReconnect: true,
            refetchOnWindowFocus: true,
        }
    ])

    const { data: topLiveMatchData, isLoading: isTopLiveMatchLoading, isError: isTopLiveMatchError } = topMatchQuery
    const { data: liveMatchData, isLoading: isLiveMatchLoading, isError: isLiveMatchError } = allMatchQuery


    useEffect(() => {

        socket.on('MATCH_END', (data) => {
            const topPrev = queryClient.getQueryData(['match/live/top'])
            queryClient.setQueryData(['match/live/top'], { ...topPrev, data: (topPrev.data ?? []).filter(item => item.slug !== data.match_slug) })

            const prev = queryClient.getQueryData(['match/live'])
            queryClient.setQueryData(['match/live'], { ...prev, data: (prev.data ?? []).filter(item => item.slug !== data.match_slug) })
        })


        socket.on('MATCH_START', (data) => {
            const topPrev = queryClient.getQueryData(['match/live/top'])
            const top_updated_data = (topPrev?.data ?? []).map(item => (item.slug !== data.match_slug) ? item : { ...item, is_started: true, score: data.score })
            queryClient.setQueryData(['match/live/top'], { ...topPrev, data: top_updated_data })

            const prev = queryClient.getQueryData(['match/live'])
            const updated_data = (prev?.data ?? []).map(item => (item.slug !== data.match_slug) ? item : { ...item, is_started: true, score: data.score })
            queryClient.setQueryData(['match/live'], { ...prev, data: updated_data })

        })


        return () => {
            socket.dispose('MATCH_END')
            socket.dispose('MATCH_START')
        }

    }, [])


    const handleBackButtonClick = () => {
        setPage(ROUTES.DETAILS)
    }


    return (
        <div className={styles.LiveMatches}>
            <BackButton isRelative={true} handleBackButton={handleBackButtonClick} />
            <SubTitle
                text={'Top Live Matches'}
                classes=''
            />

            <Row
                className={`${styles.matchRow} w-100 mt-2 mb-4`}
                gutter={[0, 24]}
            >
                {isTopLiveMatchLoading ? <Loader /> :
                    (isTopLiveMatchError || _.isEmpty(topLiveMatchData)) ?
                        <EmptyContainer component={<SubTitle text='No top live match yet' classes='fs-5 fw-lighter' />} /> :
                        (topLiveMatchData ?? []).map((match, index) => <Col
                            key={index}
                            className={`${styles.matchCard} mt-2 mb-2`}
                            lg={3} md={4} sm={6}
                        >
                            <MatchCard data={match} />
                        </Col>)
                }
            </Row>
            <SubTitle
                text={'All Live Matches'}
                classes='mb-2'
            />

            <Row
                className={`${styles.matchRow} w-100 mt-3 mb-3`}
                gutter={[0, 24]}
            >
                {isLiveMatchLoading ? <Loader /> :
                    (isLiveMatchError || _.isEmpty(liveMatchData)) ?
                        <EmptyContainer component={<SubTitle text='No live match yet' classes='fs-5 fw-lighter' />} /> :
                        (liveMatchData ?? []).map((match, index) => <Col
                            className={`${styles.matchCard} mt-2 mb-2`}
                            lg={3} md={4} sm={6}
                            key={index}
                        >
                            <MatchCard data={match} />
                        </Col>)
                }

            </Row>

        </div >
    )
}

