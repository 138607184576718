import React from 'react';

import styles from "./styles/LiveMatches.module.scss"

import { BackButton, Description, EmptyContainer, Loader, SubTitle } from '../../Components/CustomComponents';
import { Row, Col } from 'react-bootstrap';
import { ROUTES } from './constants';
import { useQuery, useQueryClient } from 'react-query';
import { Toast } from '../../Hooks/useToast';
import _ from 'lodash';
import { TournamentAPI } from '../../Api/Tournament';
import TournamentCard from '../../Components/Cards/TournamentCard';


export default function TournamentListing({ setPage }) {
    const tournamentAPI = TournamentAPI()
    const queryClient = useQueryClient();

    const { data, isLoading, isError } = useQuery({
        queryKey: ['tournament/live'],
        queryFn: async () => await tournamentAPI.getLive(),
        onSuccess: (data) => {
            data.forEach(item => queryClient.setQueryData(['tournament/' + item.slug + '/live'], item));
        },
        select: (data) => data.data,
        onError: (err) => Toast(err.message, 'error', false),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    })


    const handleBackButtonClick = () => {
        setPage(ROUTES.DETAILS)
    }

    const handleTournamentClick = (data) => {
        const payload = {
            ...data,
            previous_route: ROUTES.TOURNAMENT_LISTING
        }
        setPage(ROUTES.TOURNAMENTS, payload)
    }


    return (
        <div className={styles.LiveMatches}>
            <BackButton isRelative={true} handleBackButton={handleBackButtonClick} />

            <SubTitle
                text={'Live Tournaments'}
            />
            <Row
                className={`${styles.matchRow} w-100 mt-2 mb-2`}
                gutter={[0, 24]}
            >
                {isLoading ? <Loader /> :
                    (isError || _.isEmpty(data)) ?
                        <EmptyContainer component={<SubTitle text='No live tournament yet' classes='fs-5 fw-lighter' />} /> :
                        data.map((t_record, index) => <Col
                            className={`mt-2 mb-2`}
                            lg={4} md={6} sm={6}
                        >
                            <TournamentCard key={index} data={t_record} onClick={() => handleTournamentClick(t_record)} />
                        </Col>
                        )}
            </Row>
        </div >
    )
}

