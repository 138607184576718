import React, { useMemo } from "react";
import cssStyles from "./TeamList.module.css";

import { CardTitle, Description } from "../../../Components/CustomComponents";
import TeamDropDownMenu from "./TeamDropDownMenu";

export default function TeamList({
  data = [],
  selectedTeam,
  setSelectedTeam = () => { },
}) {
  const team = useMemo(() => {
    return data[selectedTeam];
  }, [selectedTeam]);


  return (
    <div>
      <CardTitle text="Select your Team" classes="mb-2" />
      <TeamDropDownMenu
        data={data}
        selectedTeam={selectedTeam}
        setSelectedItem={setSelectedTeam}
      />
      <div className={cssStyles.teamInfoContainer}>
        <CardTitle text="Team" />
        <Description
          text="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          classes="opacity-75 fs-9 mb-2"
        />
        <div className={`${cssStyles.userContainer} my-3`}>
          <img
            src={team?.coach_image_url}
            alt="Coach Profile"
            className={cssStyles.image}
          />
          <Description
            text={team?.coach_name}
            classes="ps-2 lc-1 fs-8"
          />
          <span className={cssStyles.coachBadge}>Coach</span>
        </div>
        {(team?.team_members ?? []).map((player, index) => {
          return (
            <div key={index} className={`${cssStyles.userContainer} my-3`}>
              <img
                src={player.image_url}
                alt="User Profile"
                className={cssStyles.image}
              />
              <Description text={player.name} styles classes="ps-2 lc-1 fs-8" />

              {/* <Badge className="p-2 fs-7" bg="" pill style={{ fontWeight: 'normal', background: '#E95B5B', marginLeft: 'auto' }}>
                                                    Remove
                                                </Badge> */}
            </div>
          );
        })}
      </div>
    </div>
  );
}
