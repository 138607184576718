import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import MagicContent from "../Tournaments/MagicContent";
import CreateTeamCard from "../Tournaments/Components/CreateTeamCard";
import { ROUTES } from "./Route";
import { BackButton } from "../../Components/CustomComponents";


const MagicBoxScreen = ({ setPage }) => {
  const [playerAge, setPlayerAge] = useState(undefined);
  const [selectedPlayer, setSelectedPlayer] = useState([]);


  const handleBackButtonClick = () => {
    setPage(ROUTES.PROFILE);
  }

  return (
    <div style={{ width: '100%' }}>
      <BackButton isRelative={true} handleBackButton={handleBackButtonClick} />
      <Row>
        <Col md={12} lg={8}>

          <MagicContent playerAge={playerAge} setPlayerAge={setPlayerAge} selectedPlayer={selectedPlayer} setSelectedPlayer={setSelectedPlayer} />
        </Col>

        <Col md={12} lg={4}>
          <div style={{ color: 'white', }}>
            <CreateTeamCard playerAge={playerAge} selectedPlayer={selectedPlayer} handleClose={handleBackButtonClick} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MagicBoxScreen;
