import React, { useState } from "react";

import styles from "../index.module.scss";

import { CardTitle, Description, EmptyContainer, Loader, SubTitle } from "../../../Components/CustomComponents";

import _ from "lodash";
import { Badge, Col, Row, Spinner } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { PlayerAPI } from "../../../Api/Player";
import { TeamAPI } from "../../../Api/Team";
import { Toast } from "../../../Hooks/useToast";


export default function UnregisterPlayer({ team_slug = '', setDetails = () => { }, group_type, handleClose }) {
    const QUERY_KEY = 'age-group/' + group_type + '/team/unregister'
    const playerAPI = PlayerAPI()

    const teamAPI = TeamAPI();
    const queryClient = useQueryClient()
    const [isSubmitted, setIsSubmitted] = useState(undefined);

    const { data, isError, isLoading } = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: async () => await playerAPI.getAgeGroupUnregisterPlayer(group_type),
        select: (data) => data.data,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    })



    const addPlayerMutation = useMutation(teamAPI.addPlayer, {
        onSuccess: ({ data: response }) => {
            const player_slug = response.player_slug
            const player_record = data.find(item => item.slug === player_slug);

            queryClient.setQueryData([QUERY_KEY], (data) => {
                return {
                    ...data,
                    data: data?.data.filter(item => item.slug !== player_slug)
                }
            })

            const new_player = {
                slug: player_record.slug,
                name: player_record.fullname,
                image_url: player_record.image_url
            }

            queryClient.setQueryData(['team'], (data) => {

                queryClient.setQueryData(['team/' + team_slug], (data) => {

                    if (data.is_registered) {
                        setDetails(details => {
                            return {
                                ...details,
                                member_count: details?.member_count + 1,
                                team_members: [
                                    ...details.team_members,
                                    new_player
                                ]
                            }
                        })
                    }

                    return {
                        ...data,
                        member_count: data?.member_count + 1,
                        team_members: [
                            ...data.team_members,
                            new_player
                        ]
                    }
                })


                return {
                    ...data,
                    data: data?.data.map(item => {
                        if (item.slug !== team_slug) return item;
                        return {
                            ...item,
                            member_count: item?.member_count + 1,
                            team_members: [
                                ...item.team_members,
                                new_player
                            ]
                        }
                    })
                }
            })

            queryClient.setQueryData(['user/player'], (player_data) => {
                return {
                    ...player_data,
                    data: player_data?.data.map(item => {
                        if (item.slug !== player_slug) return item
                        return {
                            ...item,
                            is_registered: true
                        }
                    })
                }
            })



            setIsSubmitted(undefined);
        },
        onError: (err) => {
            Toast(err.message, 'error', false)
            setIsSubmitted(undefined)
        }
    });


    const handleAddPlayer = (player_slug) => {
        if (!_.isUndefined(isSubmitted)) return;

        setIsSubmitted(player_slug);
        addPlayerMutation.mutate({ team_slug, player_slug });
    }


    return (
        <>
            <div className={'d-flex flex-row w-100 align-items-center'}>
                <div className="d-flex flex-column">
                    <CardTitle
                        text='Add New Members In Your Team' />
                    <Description
                        text='All unregistered players are listed below'
                        classes='opacity-75 fs-9'
                    />
                </div>
                <div
                    className='d-flex flex-row ms-auto align-items-center cursor-pointer'
                    onClick={() => handleClose(false)}
                >
                    <Badge className="py-2 px-4 fs-7" bg="" style={{ fontWeight: 'normal', background: '#E95B5B', marginLeft: 'auto', borderRadius: "12px" }}>
                        Cancel
                    </Badge>
                </div>
            </div>
            <div className={'py-4 px-2'}>
                {isLoading ? <Loader /> :
                    (isError || _.isEmpty(data)) ? <EmptyContainer component={<SubTitle text="No player found" classes="fs-5 fw-lighter" />} /> :
                        <Row className="gy-3">
                            {(data ?? []).map((item, index) => {
                                return (
                                    <Col xl={6} md={12} sm={6} >
                                        <div
                                            key={index}
                                            className={`${styles.userContainer} px-3 py-2 w-100`}
                                            style={{ background: "#222232", borderRadius: "12px" }}
                                        >
                                            <img
                                                src={item.image_url}
                                                alt='User Profile'
                                                className={styles.image}
                                            />
                                            <div className='d-flex flex-column ps-2'>
                                                <Description
                                                    text={item.fullname}
                                                    classes='lc-1 fs-7'
                                                />
                                                <Description
                                                    text={item.dateOfBirth}
                                                    classes='lc-1 fs-9'
                                                />
                                            </div>
                                            {
                                                (_.isUndefined(isSubmitted) || (isSubmitted !== item.slug)) ?
                                                    <Badge
                                                        className="py-2 px-4 fs-8 f-flex cursor-pointer"
                                                        bg=""
                                                        pill
                                                        style={{ fontWeight: 'normal', background: 'linear-gradient(123.9deg, #29A5DE 24.41%, #2B3C8E 94.01%)', marginLeft: 'auto' }}
                                                        onClick={() => handleAddPlayer(item.slug)}
                                                    >
                                                        Add
                                                    </Badge>
                                                    :
                                                    <Badge
                                                        className="fs-8"
                                                        bg=""
                                                        pill
                                                        style={{
                                                            padding: "0 8px",
                                                            fontWeight: 'normal',
                                                            background: 'linear-gradient(123.9deg, #29A5DE 24.41%, #2B3C8E 94.01%)',
                                                            marginLeft: 'auto',
                                                            minWidth: "60px",
                                                            minHeight: "30px",
                                                            textAlign: "center",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}
                                                    >
                                                        <Spinner style={{ fontSize: "6px" }} size="sm" />
                                                    </Badge>
                                            }
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                }
            </div>
        </>
    )
}