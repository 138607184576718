import { useSelector } from "react-redux";
import { Axios } from "../Hooks/useAxiosInstance";


export const TeamAPI = () => {

    const { route, user } = useSelector(state => state.default)

    const apis = ({ route, api_token }) => ({
        get: async () => await getRecord(route, api_token),
        post: async (data) => await postRecord(data, route, api_token),
        addPlayer: async ({ team_slug = '', player_slug = '' }) => await addPlayerRecord(team_slug, player_slug, api_token),
        removePlayer: async ({ team_slug = '', player_slug = '' }) => await removePlayerRecord(team_slug, player_slug, api_token),
        getTeam: async (slug) => await getTeamRecord(slug, route, api_token),
        getUnregisterTeam: async () => await getUnregisterTeamRecord(route, api_token),
    })

    async function getRecord(route, api_token) {
        const url = route + "team";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function postRecord(data, route, api_token) {
        const url = route + "team";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'multipart/form-data'
            }
        }
        return await Axios.post(url, data, option);
    }

    async function addPlayerRecord(team_slug = '', player_slug = '', api_token) {
        const url = route + "team/" + team_slug + '/player/' + player_slug;
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.post(url, {}, option);
    }

    async function removePlayerRecord(team_slug = '', player_slug = '', api_token) {
        const url = route + "team/" + team_slug + '/player/' + player_slug;
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.delete(url, option);
    }

    async function getTeamRecord(slug, route, api_token) {
        const url = route + "team/" + slug;
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }


    async function getUnregisterTeamRecord(route, api_token) {
        const url = route + "team/unregister";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    return apis({ route: route, api_token: user.api_token });

}

