import React, { useState } from "react";

import { ACCOUNT_ENUM } from "./Route";

import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import SetPassword from "./SetPassword";
import Register from "./Register";
import OTP from "./OTP";


export default function Account() {
    const [state, setState] = useState({
        data: {},
        route: ACCOUNT_ENUM.LOGIN
    })

    const handlePageChange = (page, data = {}) => {
        setState(prev => {
            return {
                ...prev,
                data: data,
                route: page
            }
        })
    }

    switch (state.route) {
        case ACCOUNT_ENUM.REGISTER:
            return <Register setPage={handlePageChange} />
        case ACCOUNT_ENUM.LOGIN:
            return <Login setPage={handlePageChange} />
        case ACCOUNT_ENUM.FORGOT_PASSWORD:
            return <ForgotPassword setPage={handlePageChange} />
        case ACCOUNT_ENUM.OTP:
            return <OTP setPage={handlePageChange} data={state.data} />
        case ACCOUNT_ENUM.SET_PASSWORD:
            return <SetPassword setPage={handlePageChange} />
        default:
            return <Login setPage={handlePageChange} />

    }
}