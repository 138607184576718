import React, { useEffect, useState } from "react";

import styles from "../index.module.scss";

import { CardTitle, Description, SubTitle } from "../../../Components/CustomComponents";

import { FaMapMarkerAlt } from "react-icons/fa";

import { useQueryClient } from "react-query";
import TeamMember from "./TeamMember";
import UnregisterPlayer from "./UnregisterPlayer";

export default function TeamView({ team_slug }) {
    const queryClient = useQueryClient()
    const [data, setData] = useState(queryClient.getQueryData(['team/' + team_slug]) ?? {})
    const GROUP_TYPE = data?.group_type_slug ?? ''


    const [isAddKid, setIsAddKid] = useState(false);


    useEffect(() => {
        return () => setIsAddKid(false)
    }, [])


    return (
        <div className={styles.teamInfoContainer}>
            <div className={styles.headerContainer}>
                <SubTitle
                    text={'Team View'}
                />
                {/* <span
                    className={styles.editIcon}
                 onClick={handleEditProfile}
                >
                    <EditProfile />
                </span> */}
            </div>
            <Description
                text='Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
                classes='fs-8 mb-4'
            />
            {!data.is_registered ? "" :
                <div
                    className={`${styles.ticketContainer} mt-3 mb-4`}
                >
                    <div className={styles.teamContainer}>
                        <div className={styles.tournament}>
                            <img
                                src={data.tournament_image_url}
                                alt='Tournament Profile'
                                className={styles.tournamentLogo}
                            />
                            <div className={styles.nameContainer}>
                                <CardTitle
                                    text={data.tournament_name}
                                    classes='lc-1'
                                />
                                <div className={styles.location}>
                                    <span className={styles.icon}><FaMapMarkerAlt /></span>
                                    <Description
                                        text={data.tournament_location}
                                        classes='fs-9 lc-1'
                                    />
                                    {
                                        data.is_live ?
                                            <span className={styles.liveBadge}>Live</span>
                                            : ''
                                    }
                                </div>

                            </div>

                        </div>
                        <div className={styles.team}>
                            <img
                                src={data.image_url}
                                alt='Team Profile'
                                className={styles.teamLogo}
                            />
                            <div className={styles.nameContainer}>
                                <CardTitle
                                    text={data.name}
                                    classes='lc-1'
                                />
                                <Description
                                    text={`Age groupd:${data.group_type}`}
                                    classes='fs-9'
                                />
                                <Description
                                    text={`${data.team_members?.length} members`}
                                    classes='fs-9'
                                />

                            </div>

                        </div >


                    </div >
                    <div className={styles.qrContainer}>
                        <img
                            src='/images/qr.png'
                            alt='QR Code'
                            className={styles.qrImage}
                        />
                        <Description
                            text='Team QR'
                            classes='fs-8 text-center mt-2 text-nowrap'
                        />

                    </div>

                </div >

            }
            {isAddKid ? <UnregisterPlayer team_slug={team_slug} setDetails={setData} group_type={GROUP_TYPE} handleClose={setIsAddKid} />
                : <TeamMember team_slug={team_slug} setDetails={setData} handleOpen={setIsAddKid} />}

        </div>
    )
}