import React from "react";
import { Badge } from "react-bootstrap";

const CustomBadge = ({ text, color }) => {
  const badgeStyle = {
    backgroundColor: color || "#0AA7FF",
    fontSize: "14px",
    fontWeight: "bold",
  };

  return (
    <Badge className="p-2" bg="" pill style={badgeStyle}>
      {text}
    </Badge>
  );
};

export default CustomBadge;
