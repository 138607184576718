import React, { useMemo } from 'react';

import styles from './styles/Menu.module.scss';
import { ReactComponent as EditIcon } from "./images/edit.svg"
import { ReactComponent as PasswordIcon } from "./images/password.svg"
import { ReactComponent as TermsIcon } from "./images/terms.svg"
import { ReactComponent as PrivacyIcon } from "./images/privacy.svg"


import { Description, SubTitle } from '../../Components/CustomComponents';
import { ROUTES } from './contants';

export default function Menu({ route, setRoute }) {
    const OPTIONS = useMemo(() => {
        return [
            {
                label: 'Edit Profile',
                route: ROUTES.EDIT_PROFILE,
                icon: <EditIcon />
            },
            {
                label: 'Change Password',
                route: ROUTES.CHANGE_PASSWORD,
                icon: <PasswordIcon />
            },
            {
                label: 'Terms And Conditions',
                route: ROUTES.TERMS_CONDITIONS,
                icon: <TermsIcon />
            },
            {
                label: 'Privacy Policy',
                route: ROUTES.PRIVACY_POLICY,
                icon: <PrivacyIcon />
            }]
    }, [])

    const handleMenuItemClick = (route) => {
        setRoute(route)
    }

    return (
        <div className={styles.Menu}>
            <SubTitle
                text='Setting'
            />
            <Description
                text='Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
                classes='mt-1 mb-4 fs-8'
            />
            {OPTIONS.map((item, index) => {
                return (
                    <div
                        className={`${styles.option} my-3 ${(route === item.route) ? 'border-gd' : ''}`}
                        key={index}
                        onClick={() => handleMenuItemClick(item.route)}

                    >
                        <span className={styles.icon}>{item?.icon}</span>
                        <Description
                            text={item.label}
                            classes='fs-7'
                        />
                    </div>
                )
            })}
        </div>
    )
}