import { createSlice } from '@reduxjs/toolkit'
import { ROLES } from '../constants';
import { localStorageToken } from '../Helper';
import CryptoJS from 'crypto-js';


const initialState = {
    isLoggedIn: undefined,
    isFilterModal: false,
    isSocketConnected: false,
    route: "",
    isViewer: false,
    isCoach: false,
    isPlayer: false,
    user: {}
}

export const defaultSlice = createSlice({
    name: 'default',
    initialState,
    reducers: {
        updateLogin: (state, actions) => {
            return {
                ...state,
                isLoggedIn: actions.payload

            }
        },
        logout: (state, actions) => {
            console.log("Logout")
            localStorage.removeItem(localStorageToken());
            return {
                ...initialState,
                isLoggedIn: false
            };
        },
        setLoggedIn: (state, actions) => {
            return {
                ...state,
                isLoggedIn: actions.payload
            }
        },
        socketConntected: (state, actions) => {
            return {
                ...state,
                isSocketConnected: true
            }
        },
        setFilterModal: (state, actions) => {
            return {
                ...state,
                isFilterModal: actions.payload
            }
        },
        setUser: (state, actions) => {
            const data = actions.payload?.data ?? {};
            const user_type = data?.user_type;
            const role = ROLES[user_type]
            if (!role) {
                localStorage.removeItem(localStorageToken())
                return initialState
            }
            else {
                localStorage.setItem(localStorageToken(), CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY))
                return {
                    ...state,
                    isLoggedIn: true,
                    route: role?.toLowerCase() + "/",
                    isViewer: user_type === ROLES.VIEWER,
                    isCoach: user_type === ROLES.COACH,
                    isPlayer: user_type === ROLES.PLAYER,
                    user: data
                }
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateLogin, setUser, setLoggedIn, socketConntected, setFilterModal, logout } = defaultSlice.actions

export default defaultSlice.reducer