import React, { useState } from "react";

import OtpInput from 'react-otp-input';

import AccountLayout from "../../Components/Layouts/AccountLayout";
import { Button, Description, InputOTP, Title } from "../../Components/CustomComponents";
import { ACCOUNT_ENUM } from "./Route";
import { Toast } from "../../Hooks/useToast";
import { useVerifyRegisterOTPMutation } from "../../services/UserService";
import { setUser } from "../../Features/DefaultReducer";
import { useDispatch } from "react-redux";

export default function OTP({ data = {}, setPage }) {
    const OTP_LENGTH = 6;
    const dispatch = useDispatch()
    const [verifyRegister] = useVerifyRegisterOTPMutation();
    const [otp, setOtp] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)

    const handleBackButtonClick = () => {
        setPage(data?.previousRoute ?? ACCOUNT_ENUM.FORGOT_PASSWORD);
    }

    const handleSubmit = async () => {
        if (isSubmitted) return;
        try {
            setIsSubmitted(true)
            const payload = {
                email: data.email,
                otp: otp
            }
            const response = await verifyRegister(payload).unwrap();
            dispatch(setUser(response))
            Toast('User logged in successfully', 'success', false)
        }
        catch (err) {
            Toast(err.message, 'error', false)
            setIsSubmitted(false)
        }
        // setPage(ACCOUNT_ENUM.SET_PASSWORD);
    }

    return (
        <AccountLayout
            hasBackButton={true}
            handleBackButton={handleBackButtonClick}
        >
            <Title text="OTP Verification" classes="mb-2" />
            <Description
                text={`Please enter 6-digit code we have sent you on your phone ${data.email}`}
                classes="text-center fs-7 mb-4"
            />

            <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={OTP_LENGTH}
                renderSeparator={<span> </span>}
                renderInput={(props) => <InputOTP props={props} />}
            />
            <Button
                text='Continue'
                classes="mt-5 mb-2"
                onClick={handleSubmit}
                disabled={otp.length !== OTP_LENGTH}
                isSubmitted={isSubmitted}
            />

        </AccountLayout>
    )
}